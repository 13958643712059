
import {
  login,
  checkAuthToken,
  resetPassword,
  resetUserPassword,
  getAccountAndProfile,
  register,
  confirmAccount,
  registerEntityUser,
  getUser
} from "@/services/api/auth"
import {
  AUTH_LOGIN,
  AUTH_LOG_OUT,
  AUTH_CHECK_TOKEN,
  AUTH_GET_ACCOUNT_AND_PROFILE,
  AUTH_REGISTER,
  CONFIRM_ACCOUNT,
  AUTH_CONFIRM_RESET_PASSWORD,
  AUTH_RESET_PASSWORD,
  AUTH_REGISTER_ENTITY_USER,
  GET_TRANSACTIONS
} from "../actions"
import {
  SET_CURRENT_USER,
  SET_USER_LOGGED_IN,
  SET_USER_LOGGED_OUT,
  SET_CURRENT_AUTH
} from "../mutations"
import menus from '../user/menus'
import Vue from "vue"
import { userAccountProfileModules } from "@/store/utils"
import { getUserAccountProfileModule } from "@/store/utils"

import store from "@/store"


export const state = {
  isLoggedIn: false,
  user: {}
}
export const mutations = {
  [SET_USER_LOGGED_IN](state) {
    state.isLoggedIn = true
  },
  [SET_USER_LOGGED_OUT](state) {
    state.isLoggedIn = false;
  },
  [SET_CURRENT_USER](state, user) {
    state.user = user
  },
  [SET_CURRENT_AUTH](state, authorized) {
    state.authorized = authorized
  }

}
export const actions = {
  async [AUTH_LOGIN]({ commit }, { email, password }) {
    const loginReq = await login({ email, password });

    if (loginReq && loginReq.data && loginReq.data.authorized) {
      const { token, user, authorized } = loginReq.data
      localStorage.setItem("ACCESS_TOKEN", token);
      commit(SET_CURRENT_USER, user)
      commit(SET_USER_LOGGED_IN)
      return loginReq.data;
    } else {
      return null
    }
  },
  async [AUTH_CHECK_TOKEN]({ commit }) {
    try {
      const tokenStatus = await checkAuthToken();
      commit(SET_CURRENT_USER, { user_type: tokenStatus.data.type })
      commit(SET_USER_LOGGED_IN)
      return tokenStatus.data
    } catch (e) {
      throw new Error(e)
    }
  },
  async [AUTH_GET_ACCOUNT_AND_PROFILE]({ commit, dispatch, state }) {
    try {
      let response = {}
      const { user } = state
      if (user.user_type !== 'tourist') {
        response = await getAccountAndProfile();
        if (response.data && response.data.user) {
          const { user, profile, } = response.data
          store.commit(`${userAccountProfileModules[user.user_type]}/UPDATE_ACCOUNT`, profile)
          commit(SET_CURRENT_USER, user)
        }
      } else {
        response = await getUser();
        commit(SET_CURRENT_USER, response.data)
        response.data = { user: response.data }
        // await dispatch(`tourist/${GET_TRANSACTIONS}`, null, { root: true })
      }
      return response.data
    } catch (err) {
      console.error(err)
    }
  },
  [AUTH_LOG_OUT]({ commit }, payload) {
    localStorage.clear();
    commit(SET_USER_LOGGED_OUT)
  },
  async [AUTH_REGISTER]({ commit }, { payload }) {
    const { email, password, userType, phone } = payload
    const response = await register({ email, password, userType, phone })
    return response
  },
  async [AUTH_REGISTER_ENTITY_USER]({ commit }, { payload }) {
    const response = await registerEntityUser(payload)
    const storeModule = getUserAccountProfileModule()
    if (response && response.data) {
      store.commit(`${storeModule}/UPDATE_ACCOUNT`, response.data)
      return response.data
    }
    return response
  },
  async [CONFIRM_ACCOUNT]({ commit, dispatch, rootState }, { payload }) {
    try {
      const { token } = payload
      localStorage.setItem('ACCESS_TOKEN', token)
      const response = await confirmAccount(token)
      if (response && response.activated) {
        Vue.prototype.$notification.success({
          message: 'Successful Activated',
          description: 'Account confirmed, please login to continue',
        })
        return response
      } else {
        throw new Error('Error with your request.')
      }
    } catch (e) {
      throw new Error(e)
    }
  },
  async [AUTH_RESET_PASSWORD]({ commit, dispatch, rootState }, { email }) {
    try {
      const response = await resetPassword({ email, platform: "web" })
      if (response) {
        Vue.prototype.$notification.success({
          message: 'Password reset request successful',
          description: 'A password reset link has been sent to your email',
        })
        return response
      } else {
        throw new Error('Error with your request.')
      }
    } catch (e) {
      throw new Error(e)
    }
  },
  async [AUTH_CONFIRM_RESET_PASSWORD]({ _ }, { payload }) {
    try {
      const { token, password } = payload
      localStorage.setItem('ACCESS_TOKEN', token)
      const response = await resetUserPassword({ password });
      if (response && response.status === 'success') {
        Vue.prototype.$notification.success({
          message: 'Successful',
          description: response.message,
        })
        return response
      } else {
        throw new Error('Error with your request.')
      }

    } catch (e) {
      throw new Error(e)
    }
  },
}

export const getters = {
  currentUser: (state) => state.user,
  currentUserType: (state) => state.user.user_type,
  isLoggedIn: (state) => state.isLoggedIn,
  userMenus: (state, rootGetters) => rootGetters && rootGetters.currentUser ? menus[rootGetters.currentUser.user_type] : null,
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}