import store from "@/store"

export const userAccountProfileModules = {
  admin: "admin", //NOT_SURE
  tourist: "tourist",
  tour_sight: "toursight",
  tour_operator: "touroperator",
  hotel_and_accommodations: "accommodations",
  food_and_drinks: "nourishment",
  events: "events",
  transport: "transport"
}

export function getUserAccountProfileModule(givenUserType) {
  if (givenUserType) {
    return userAccountProfileModules[givenUserType]
  }
  const userType = store.getters['auth/currentUserType']
  return userAccountProfileModules[userType]
}