<template>
  <div id="app">
    <localization></localization>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Localization from "@/localization";
import { isBoolean } from "lodash";
import store from "@/store"
import { userAccountProfileModules } from "@/store/utils"

export default {
  name: "app",
  components: { Localization },
  computed: {
    ...mapState(['settings']),
    ...mapState('user', ['authorized']),
     ...mapGetters('auth',["isLoggedIn", "currentUserType"]),
    nextRoute() {
      return this.$route.query.redirect || '/'
    },
    currentRoute() {
      return this.$route.path
    }
  },
  async beforeCreate() {
    //dosent need to run on register, store does not seem to be initialized even at created
    // const isNotRegisterationRoute = window.location.pathname !== '/auth/register'
    //  if(isNotRegisterationRoute) {
    //     await this.$store.dispatch('user/CHECK_AUTH')
    //     await this.$store.dispatch('user/LOAD_CURRENT_USER_ACCOUNT')
    //     this.$store.commit('SET_PRIMARY_COLOR', { color: this.settings.primaryColor })
    //     this.$store.commit('SET_THEME', { theme: this.settings.theme })
    //   }
  },
  watch: {
    isLoggedIn: {
      handler(loggedIn) {
        if (loggedIn) {
          const userType = this.currentUserType
          const storeModule = userAccountProfileModules[userType]
          // switch(userType) {
          //   case "hotel_and_accommodations":
          //     this.$router.push(`/accommodations/dashboard`);
          //     break;
          //   case "food_and_drinks":
          //     this.$router.push("/foodAndDrinks/dashboard");
          //     break;
          //   case "events":
          //     this.$router.push("/events/dashboard");
          //     break;
          //   case "tour_operator":
          //     this.$router.push("/tourOperator/dashboard")
          //     break
          //   case "tour_sight":
          //     this.$router.push("/tourSight/dashboard")
          //     break;
          //   case "transport":
          //     this.$router.push(`/transport/dashboard`);
          //     break;
          //   case "tourist":
          //     console.log("Going to dashboard")
          //     this.$router.push(`/tourist`);
          //     break;
          //   case "admin":
          //     this.$router.push(`/admin/dashboard`);
          //     break;
          // }
        } else {
          if (this.$route.path !== "/auth/login") {
            this.$router.push("/auth/login");
          }
        }
      },
      immediate: false,
    },
    "$store.state.settings.theme"(theme) {
      this.$store.commit("SET_THEME", { theme });
    },
  },
};
</script>
