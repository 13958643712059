const component = () => import("@/pages/new/public/facilities/services")
const hotel_component = () => import("@/pages/new/public/facilities/services/hotels")

export default [
  {
    path: 'gta-facilities/tour-sites/:id/services',
    meta: {
      title: 'Attraction Site',
    },
    props: () => ({
      entity_type: "tour_sight",
      base_url: "tour-sites",
    }),
    component
  },
  {
    path: 'gta-facilities/transports/:id/services',
    meta: {
      title: 'Transports',
    },
    props: () => ({
      entity_type: "transport",
      base_url: "transports"
    }),
    component
  },
  {
    path: 'gta-facilities/food-and-beverages/:id/services',
    meta: {
      title: 'Food & Beverages',
    },
    props: () => ({
      entity_type: "food_and_drinks",
      base_url: "food-and-beverages",
    }),
    component
  },
  {
    path: 'gta-facilities/events-organizers/:id/services',
    meta: {
      title: 'Events',
    },
    props: () => ({
      entity_type: "events",
      base_url: "events",
    }),
    component
  },
  {
    path: 'gta-facilities/tour-operators/:id/services',
    props: () => ({
      entity_type: "tour_operator",
      base_url: "tour-operators",
    }),
    meta: {
      title: 'Tour Operator',
    },
    component
  },
  {
    path: 'gta-facilities/hotel-and-accommodations/:id/services',
    props: () => ({
      entity_type: "hotel_and_accommodations",
      base_url: "hotel-and-accommodations",
    }),
    meta: {
      title: 'Hotels and Accommodations',
    },
    component: hotel_component
  },
]