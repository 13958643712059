import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import AuthRoutes from '@/routes/auth'
import AdminRoutes from '@/routes/admin'
import FoodAndDrinksRoute from '@/routes/food_and_drinks'
import EventsRoute from '@/routes/events'
import TouristRoutes from '@/routes/tourist'

import TourOperator from "@/routes/tourOperator"
import TourSight from "@/routes/toursight"
import Accommodations from "@/routes/accommodations"
import { getUserAccountProfileModule } from './store/utils'
import Transport from "@/routes/transport"
import PublicRoutes from "@/routes/public"
import {
  AUTH_CHECK_TOKEN,
  AUTH_GET_ACCOUNT_AND_PROFILE,
  AUTH_LOG_OUT
} from "@/store/actions"
import Loading from 'vue-loading-overlay';
// Init plugin
Vue.use(Loading);

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      meta: {
        title: 'Home',
        authRequired: true,
        redirectToBrowseIfNotLoggedIn: true,
      },
    },
    PublicRoutes,
    AuthRoutes,
    Accommodations,
    AdminRoutes,
    FoodAndDrinksRoute,
    EventsRoute,
    TourOperator,
    TourSight,
    Transport,
    TouristRoutes,
    {
      path: '/terms',
      meta: {
        title: 'Terms & Conditions',
      },
      component: () => import('@/pages/new/public/terms/terms.vue'),
    },
    {
      path: '/privacy',
      meta: {
        title: 'Privacy & Policy',
      },
      component: () => import('@/pages/new/public/terms/privacy.vue'),
    },
    {
      path: '/404',
      meta: {
        title: 'Error 404',
      },
      component: () => import('@/pages/new/public/errors/error-400.vue'),
    },
    {
      path: '/500',
      meta: {
        title: 'Error 500',
      },
      component: () => import('@/pages/new/public/errors/error-500.vue'),
    },
    {
      path: '/403',
      meta: {
        title: 'Error 403',
      },
      component: () => import('@/pages/new/public/errors/error-403.vue'),
    },
  ],
})

window.loader = {}

async function touristBeforeEachRouteHandler(user, to, next) {
  if (user.has_completed_onboarding) {
    store.commit('CHANGE_SETTING', { setting: 'showSideBar', value: true })
    const redirect = localStorage.getItem("entity_services_redirect")

    if (redirect) {
      localStorage.removeItem("entity_services_redirect")
      return next(redirect)
    }

    if (to.path === '/auth/login' || to.path === "/") {
      console.log('--> redirect to tourist home')
      return next(`/tourist`);
    } else {
      return next();
    }
  } else {
    if (to.path !== "/tourist/onboarding") {
      store.commit('CHANGE_SETTING', { setting: 'showSideBar', value: false })
      return next("/tourist/onboarding")
    }
    return next();
  }
}

async function getAuthUserData() {
  AUTH_LOG_OUT
  await store.dispatch(`auth/${AUTH_CHECK_TOKEN}`);
  const accountAndProfile = await store.dispatch(`auth/${AUTH_GET_ACCOUNT_AND_PROFILE}`);
  // console.log("accountAndProfile", accountAndProfile)
  return accountAndProfile
}

const userHasRoutePermission = (route, userPermissions) => {
  if (route.permissions && route.permissions.length > 0) {
    for (const permission of route.permissions) {
      if (!userPermissions[permission]) {
        return false;
      }
    }
    return true
  }
  return true;
}

router.beforeEach(async (to, from, next) => {
  // console.log("roles", to.meta.roles);
  // console.log("from", from.path);
  // console.log("to", to.path);
  // console.log("meta", to.meta);
  if (to.meta && to.meta.authRequired) {
    try {
      const token = localStorage.getItem("ACCESS_TOKEN");
      if (!token) {
        // console.log("no token available")
        if (to.meta.redirectToBrowseIfNotLoggedIn) {
          return next("/browse")
        } else if (to.meta.authRequired) {
          return next({ path: "/auth/login", query: { redirect: to.fullPath } });
        } else {
          return next();
        }
      }
      // console.log("token available")

      const { user, profile } = await getAuthUserData()
      const basePath = `/${getUserAccountProfileModule()}`

      if (to.meta && to.meta.roles && !to.meta.roles.includes(user.user_type)) {
        return next("/auth/403")
      }

      if (!userHasRoutePermission(to.meta, user.permissions)) {
        return next("/auth/403")
      }

      if (user && user.user_type === "tourist") {
        // console.log("--> user is tourist")
        touristBeforeEachRouteHandler(user, to, next)
      } else if (user && user.user_type === "admin") {
        if (to.path === '/auth/login' || to.path === '/') {
          return next(`${basePath}/dashboard`);
        }
        else {
          return next();
        }
      } else {
        switch (user.user_type) {
          case "tour_sight":
          case "tour_operator":
          case "hotel_and_accommodations":
          case "food_and_drinks":
          case "events":
          case "transport":
            if (profile.has_completed_onboarding && profile.meta.is_approved) {
              console.log("HAS COMPLETED ONBOARDING")
              store.commit('CHANGE_SETTING', { setting: 'showSideBar', value: true })
              if (to.path === '/auth/login' || to.path === "/") {
                console.log("PROCEED TO DASHBOARD")
                return next(`${basePath}/dashboard`);
              }
              else {
                if (to.query.from) return next(to.query.from)
                return next();
              }
            }
            else if (to.path !== `${basePath}/onboarding` && !profile.has_completed_onboarding) {
              store.commit('CHANGE_SETTING', { setting: 'showSideBar', value: false })
              return next(`${basePath}/onboarding`);
            }
            else if (to.path !== `${basePath}/dashboard` && profile.has_completed_onboarding && !profile.meta.is_approved) {
              return next(`${basePath}/dashboard`);
            }
            break;
        }
      }
    } catch (error) {
      await store.dispatch(`auth/${AUTH_LOG_OUT}`);

      if (to.path !== "/auth/login" || to.path !== "/browse") {
        return next('/browse');
      }
      return next("/auth/login");
    }
  } else {
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (token) {
      if (!to.meta || !to.meta.authRequired) {
        try {
          await getAuthUserData()
          const basePath = `/${getUserAccountProfileModule()}`
          return next(`${basePath}/dashboard`)
        } catch (error) {
          return next()
        }
      }
    }
    return next();
  }
  next()
})

export default router
