import store from "@/store"

const userUrlPrefixes = {
  admin: "admin", //NOT_SURE
  tourist: "tourist",
  tour_sight: "toursight",
  tour_operator: "touroperator",
  hotel_and_accommodations: "accommodations",
  food_and_drinks: "foodanddrinks",
  events: "events",
  transport: "transport"
}

export function getUrlPrefix(userType=null) {
  if (userType) {
    return userUrlPrefixes[userType]
  }
  const userTypeFromStore = store.getters['auth/currentUserType']
  return userUrlPrefixes[userTypeFromStore]
}