<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <a-avatar shape="circle" icon="user" size="large" :class="$style.avatar" />
    <a-menu slot="overlay">
      <!-- <a-menu-item>
        <div>
          <strong>{{ $t('topBar.profileMenu.hello') }}, {{ user.name || 'Anonymous' }}</strong>
        </div>
        <div>
          <strong class="mr-1">{{ $t('topBar.profileMenu.billingPlan') }}:</strong> Professional
        </div>
        <div>
          <strong class="mr-1">{{ $t('topBar.profileMenu.role') }}:</strong>
          {{ user.role || '—' }}
        </div>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <div>
          <strong class="mr-1">{{ $t('topBar.profileMenu.email') }}:</strong>
          {{ user.email || '—' }}
        </div>
        <div>
          <strong class="mr-1">{{ $t('topBar.profileMenu.phone') }}:</strong> —
        </div>
      </a-menu-item>
      <a-menu-divider /> -->
      <a-menu-item>
        <a
          href="javascript: void(0);"
          @click="$router.push(`/${getUserAccountProfileModule}/profile`)"
        >
          <i class="fe fe-user mr-2"></i>
          {{ $t("topBar.profileMenu.editProfile") }}
        </a>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <a href="javascript: void(0);" @click="logoutUser">
          <i class="fe fe-log-out mr-2"></i>
          {{ $t("topBar.profileMenu.logout") }}
        </a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { AUTH_LOG_OUT } from "@/store/actions";

export default {
  data: function () {
    return {
      count: 7,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    ...mapActions("auth", {
      logout: AUTH_LOG_OUT,
    }),
    addCount() {
      this.count++;
    },
    async logoutUser() {
      await this.logout();
      this.$router.push("/").catch(() => {});
    },
  },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
