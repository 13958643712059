import Vue from 'vue'
import Vuex from 'vuex'
import { defaultUserMedia } from "@/store/defaultData"
import { getUsers, getUser, dashboardInit, getPendingUsers, getRejectedUsers, approveUser, activateUser } from '@/services/api/admin'

Vue.use(Vuex)

export default {  
  namespaced: true,
  state: {
    users: [],
    user: {},
    loading: false,
    account: {
      media: defaultUserMedia,
    },
  },
  mutations: {
    SET_USERS(state, payload) {
      state.users = payload
    },
    SET_USER(state, payload) {
      state.user = payload
    },
    REMOVE_USER(state, payload) {
      state.users.splice(state.users.findIndex(x => x._id === payload._id), 1);
    },
    UPDATE_ACCOUNT(state, payload) {
      const update = { ...state.account, ...payload }
      Object.assign(state, {
        account: update
      })
    },
  },
  actions: {
    async GET_USERS({ commit }, payload) {
      try {
        const { ...query } = payload
        const loadUsers = await getUsers(query)
        if (loadUsers.data.status !== 'success' || !loadUsers.data) {
          throw (new Error('Error loading users'))
        }
        const users = loadUsers.data.data
        commit('SET_USERS', users)
        return users
      } catch (e) {
        return false
      }
    },
    async GET_PENDING_USERS({ commit }) {
      try {
        const loadUsers = await getPendingUsers()
        if (loadUsers.data.status !== 'success' || !loadUsers.data) {
          throw (new Error('Error loading users'))
        }
        const users = loadUsers.data.data
        commit('SET_USERS', users)
        return users
      } catch (e) {
        return false
      }
    },
    async GET_REJECTED_USERS({ commit }) {
      try {
        const loadUsers = await getRejectedUsers()
        if (loadUsers.data.status !== 'success' || !loadUsers.data) {
          throw (new Error('Error loading users'))
        }
        const users = loadUsers.data.data
        commit('SET_USERS', users)
        return users
      } catch (e) {
        return false
      }
    },
    async GET_USER({ commit }, { id, query }) {
      try {
        const loadUser = await getUser(id, query)
        if (loadUser.data.status !== 'success' || !loadUser.data) {
          throw (new Error('Error loading users'))
        }
        const user = loadUser.data.data
        commit('SET_USER', user)
        return user
      } catch (e) {
        return false
      }
    },
    async DASHBOARD_INIT() {
      try {
        const loadData = await dashboardInit()
        if (loadData.data.status !== 'success' || !loadData.data) {
          throw (new Error('Error initializing dashboard'))
        }
        const data = loadData.data.data
        return data
      } catch (e) {
        return false
      }
    },
    async APPROVE_USER({ commit }, {id, user_type, approve}) {
      try {
        const data = {
          user_type,
          approve
        }
        const loadUser = await approveUser(id, data)
        if (loadUser.data.status !== 'success' || !loadUser.data) {
          throw (new Error('Error loading users'))
        }
        const user = loadUser.data.data
        commit('SET_USER', user)
        commit('REMOVE_USER', user)
        return user
      } catch (e) {
        return false
      }
    },
    async ACTIVATE_USER({ commit }, id) {
      try {
        const loadUser = await activateUser(id)
        if (loadUser.data.status !== 'success' || !loadUser.data) {
          throw (new Error('Error loading users'))
        }
        const user = loadUser.data.data
        commit('REMOVE_USER', user)
        return user
      } catch (e) {
        return false
      }
    },
  },
  getters: {
    users: state => state.users,
    user: state => state.user,
  },
}
