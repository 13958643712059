import Axios from '@/services/axios'
import { getAuthHeader } from "@/helpers"

export async function publicGetEntities(query) {
  const response = await Axios.get('public/entities-search', { headers: getAuthHeader(), params: query })
  return response.data
}

export async function publicGetEntity(query) {
  const response = await Axios.get('public/entity', { headers: getAuthHeader(), params: query })
  return response.data
}