import {
  UPDATE_PROFILE,
  GET_PROFILE,
  COMPLETE_ONBOARDING,
  UPDATE_PROFILE_SERVICE,
  DELETE_PROFILE_SERVICE,
  UPDATE_PROFILE_BANK_INFORMATION,
  COMPLETE_TOURIST_ONBOARDING
} from '@/store/actions'

import {
  addOrUpdateService,
  deleteService,
  getProfile,
  updateProfile,
  saveBankInformation,
  completeOnboarding,
  completeTouristOnboarding,
} from '@/services/api/profile'

import store from "@/store"
import { getUserAccountProfileModule } from "@/store/utils"


export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async [UPDATE_PROFILE_SERVICE]({ commit }, payload) {
      try {
        const response = await addOrUpdateService(payload)
        const storeModule = getUserAccountProfileModule()

        if (response && response.data) {
          commit(`${storeModule}/UPDATE_ACCOUNT`, response.data, { root: true });

          return response.data.services
        }

      } catch (e) {
        throw new Error(e)
      }
    },
    async [UPDATE_PROFILE]({ commit, state, dispatch }, payload) {
      const storeModule = getUserAccountProfileModule()
      if (storeModule === 'tourist') {
        return dispatch(`tourist/${UPDATE_PROFILE}`, payload, { root: true })
      }
      const response = await updateProfile(payload)
      if (response && response.data) {
        store.commit(`${storeModule}/UPDATE_ACCOUNT`, response.data)
        return response.data
      }
      return response
    },
    async [UPDATE_PROFILE_BANK_INFORMATION]({ commit, state }, payload) {
      const response = await saveBankInformation(payload)
      const storeModule = getUserAccountProfileModule()
      if (response && response.data) {
        store.commit(`${storeModule}/UPDATE_ACCOUNT`, response.data)
        return response.data
      }
      return response
    },
    async [DELETE_PROFILE_SERVICE]({ commit, state }, id) {
      try {
        const response = await deleteService({ service_id: id })
        const storeModule = getUserAccountProfileModule()
        if (response && response.data) {
          store.commit(`${storeModule}/UPDATE_ACCOUNT`, response.data)
          return response.data.services
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    async [GET_PROFILE]({ commit, state }) {
      try {
        const response = await getProfile()
        const storeModule = getUserAccountProfileModule()
        if (response && response.data) {
          store.commit(`${storeModule}/UPDATE_ACCOUNT`, response.data)
          return response.data
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    async [COMPLETE_ONBOARDING]({ commit, state }, payload) {
      const response = await completeOnboarding(payload)
      const storeModule = getUserAccountProfileModule()

      if (response && response.data) {
        store.commit(`${storeModule}/UPDATE_ACCOUNT`, response.data)
        store.commit('misc/SET_STATE', { archivedGeoData: { states: [], cities: [] } }, { root: true })
        commit('CHANGE_SETTING', { setting: 'showSideBar', value: true }, { root: true })
        return response.data
      }
      return response
    },
    async [COMPLETE_TOURIST_ONBOARDING]({ commit, state }, payload) {
      const response = await completeTouristOnboarding(payload)
      if (response && response.data) {
        store.commit("auth/SET_CURRENT_USER", response.data)
        return response.data
      }
    }
  }
}
