import axios from '@/services/axios'
import { getAuthHeader } from "@/helpers"

export function getCountries(criteria = 'all') {
  return axios({
    method: "GET",
    url: '/misc/countries',
    params: {
      criteria
    },
    headers: getAuthHeader()
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)));
}

export function getGeoData(geoId) {
  return axios({
    method: "GET",
    url: '/misc/geo-data',
    params: {
      geo_id: geoId
    },
    headers: getAuthHeader()
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)));
}

export function getBanks(countryIsoCode) {
  return axios({
    method: "GET",
    url: '/misc/banks',
    params: {
      country_iso_code: countryIsoCode
    },
    headers: getAuthHeader()
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)));
}

export async function getBanksBranches(bankId) {
  return axios({
    method: "GET",
    url: '/misc/bank/branch',
    params: {
      bank_id: bankId
    },
    headers: getAuthHeader()
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)));
}

export async function confirmTransaction(payload) {
  console.log(payload)
  return axios({
    method: "POST",
    url: 'misc/confirm-ticket',
    data: payload,
    headers: getAuthHeader()
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)));
}


export async function confirmCashPayment(payment_code) {
  return await axios.post("misc/confirm-cash-payment", { payment_code }, { headers: getAuthHeader() })
}

export async function getTransaction(transaction_id) {
  return axios.get(`misc/get-transaction`, { headers: getAuthHeader(), params: { transaction_id } }) //get-transaction
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)))
}