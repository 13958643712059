import axios from '@/services/axios'
import { getAuthHeader } from "@/helpers"
import store from "store";
export function login({ email, password }) {
  return axios({
    method: "POST",
    url: '/auth/login',
    data: {
      email,
      password,
      platform: 'web',
    }
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)))
}

export function register({ email, password, userType, phone }) {

  return axios({
    method: "POST",
    url: '/auth/register',
    data: {
      email,
      password,
      user_type: userType,
      phone,
      platform: 'web',
    }
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)))
}

export function checkAuthToken() {
  return axios({
    method: "POST",
    url: '/auth/check',
    headers: getAuthHeader()
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)))
}

export async function getAccountAndProfile() {
  return axios({
    method: "GET",
    url: '/auth/user-data',
    headers: getAuthHeader()
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)))
}
export async function confirmAccount() {
  return axios
    .post('/auth/confirm-account', null, { headers: getAuthHeader() })
    .then(response => {
      store.remove('ACCESS_TOKEN')
      return response.data.data
    })
    .catch(err => console.log(err))
}

export async function resetPassword(payload) {
  return axios
    .post('/auth/password/reset', payload, { headers: getAuthHeader() })
    .then(response => {
      store.remove('ACCESS_TOKEN')
      return response.data
    })
    .catch(err => console.log(err))
}
// export async function confirmResetPassword() {
//   return axios
//     .post('/auth/password/reset/confirm-otp',null,{headers: getAuthHeader()})
//     .then(response => {
//       store.remove('ACCESS_TOKEN')
//       return response.data
//     })
//     .catch(err => console.log(err))
// }

export async function resetUserPassword(payload) {
  return axios
    .post('/auth/password/change', payload, { headers: getAuthHeader() })
    .then(response => {
      store.remove('ACCESS_TOKEN')
      return response.data
    })
    .catch(err => console.log(err))
}



export async function registerEntityUser(payload) {
  const response = await axios({
    method: "POST",
    url: '/auth/add-user',
    data: payload,
    headers: getAuthHeader()
  })
  return response.data
}

export async function getUser() {
  const response = await axios({
    method: "GET",
    url: '/auth/account',
    headers: getAuthHeader()
  })
  return response.data
}