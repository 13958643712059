var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',[_c('a-layout-content',[_c('div',{class:{
        [_vm.$style.container]: true,
        cui__layout__squaredBorders: _vm.settings.isSquaredBorders,
        cui__layout__cardsShadow: _vm.settings.isCardShadow,
        cui__layout__borderless: _vm.settings.isBorderless,
        [_vm.$style.white]: _vm.settings.authPagesColor === 'white',
        [_vm.$style.gray]: _vm.settings.authPagesColor === 'gray',
      },style:({
        backgroundImage:
          _vm.settings.authPagesColor === 'image'
            ? `url(resources/images/content/photos/7.jpg)`
            : 'none',
      })},[_c('div',{class:{
          [_vm.$style.topbar]: true,
          [_vm.$style.topbarGray]: _vm.settings.isGrayTopbar,
        }},[_c('router-link',{attrs:{"to":"/"}},[_c('div',{class:_vm.$style.logoContainer},[_c('div',{class:_vm.$style.logo},[_c('img',{staticClass:"mr-2",staticStyle:{"width":"150px"},attrs:{"src":require("@/assets/images/logo.png"),"alt":"Visit Ghana"}})])])]),_c('div',{staticClass:"d-none d-sm-block"},[_c('router-link',{staticClass:"font-size-16 kit__utils__link",attrs:{"to":"/auth/login"}},[_vm._v("Login")]),_c('a-dropdown',{staticClass:"ml-4"},[_c('a',{staticClass:"font-size-16 kit__utils__link",on:{"click":(e) => e.preventDefault()}},[_vm._v(" Sign Up ")]),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{key:"0"},[_c('router-link',{staticClass:"font-size-16 kit__utils__link",attrs:{"to":"/auth/register"}},[_vm._v("As Visitor")])],1),_c('a-menu-item',{key:"1"},[_c('router-link',{staticClass:"font-size-16 kit__utils__link",attrs:{"to":"/auth/register-entity"}},[_c('span',[_vm._v("As Service Provider")])])],1),_c('a-menu-divider')],1)],1)],1)],1),_c('div',{class:_vm.$style.containerInner},[_c('transition',{attrs:{"name":_vm.settings.routerAnimation,"mode":"out-in"}},[_c('router-view')],1)],1),_c('div',{staticClass:"mt-auto pb-5 pt-5"},[_c('div',{staticClass:"text-center"},[_vm._v(" Copyright © 2017-"+_vm._s(new Date().getFullYear())+" Ghana Tourism Authority | "),_c('a',{attrs:{"href":"/privacy","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Privacy Policy")])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }