const component = () => import("@/pages/new/public/facilities/preview")

export default [
  {
    path: 'gta-facilities/tour-sites/:id',
    meta: {
      title: 'Attraction Site',
    },
    props: () => ({
      entity_type: "tour_sight",
      base_url: "tour-sites",
      title: 'Attraction Site',
    }),
    component
  },
  {
    path: 'gta-facilities/transports/:id',
    meta: {
      title: 'Transports',
    },
    props: () => ({
      entity_type: "transport",
      base_url: "transports",
      title: 'Transports',
    }),
    component
  },
  {
    path: 'gta-facilities/food-and-beverages/:id',
    meta: {
      title: 'Food & Beverages',
    },
    props: () => ({
      entity_type: "food_and_drinks",
      base_url: "food-and-beverages",
      title: 'Food & Beverages',
    }),
    component
  },
  {
    path: 'gta-facilities/events-organizers/:id',
    meta: {
      title: 'Events',
    },
    props: () => ({
      entity_type: "events",
      base_url: "events",
      title: 'Events',
    }),
    component
  },
  {
    path: 'gta-facilities/tour-operators/:id',
    props: () => ({
      entity_type: "tour_operator",
      base_url: "tour-operators",
      title: 'Tours & Travel',
    }),
    meta: {
      title: 'Tour Operator',
    },
    component
  },
  {
    path: 'gta-facilities/hotel-and-accommodations/:id',
    props: () => ({
      entity_type: "hotel_and_accommodations",
      base_url: "hotel-and-accommodations",
    }),
    meta: {
      title: 'Hotels and Accommodations',
    },
    component
  },
]