import { format } from "date-fns"
export const getAuthHeader = () => {
  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN")
  return {
    Authorization: `Bearer ${ACCESS_TOKEN}`,
    'Content-Type': 'application/json'
  }
}

export const getMultiFormAuthHeader = () => {
  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN")
  return {
    Authorization: `Bearer ${ACCESS_TOKEN}`,
    'Content-Type': 'multipart/form-data'
  }
}

export function coercePhone(number, prefix) {
  return {
    number,
    formatted: parseInt(number[0]) === 0 ? `${prefix}${number.slice(1)}` : number,
  }
}

export function validateId(idType, idNumber) {
  let regexes = {
    DRIVERS_LICENSE: /^[A-Z0-9]{6,10}$/i,
    SSNIT: /^[A-Z]{1}[A-Z0-9]{12,14}$/i,
    VOTER_ID: /^[0-9]{10,12}$/,
    PASSPORT: /^G[A-Z0-9]{7,9}$/i,
    NATIONAL_ID: /^GHA-[A-Z0-9]{9}-[A-Z0-9]{1}$/i
  }
  switch (idType) {
    case 'national_id':
      return regexes.NATIONAL_ID
    case 'passport_number':
      return regexes.PASSPORT
    case 'voter_id':
      return regexes.VOTER_ID
    case 'drivers_license':
      return regexes.DRIVERS_LICENSE
    default:
      return ''
  }
}

export function formatAmount(amount) {
  return "GH¢ " + formatMoney(amount);
}

export function formatAmountOnly(amount) {
  return formatMoney(amount);
}

export function formatDate(date, formatStr = "dd/MM/yyyy") {
  return format(new Date(date), formatStr);
}

export function formatDateDistance(date) {
  return formatDistance(new Date(date), new Date(), { addSuffix: true });
}

function formatMoney(
  number,
  decPlaces,
  decSep,
  thouSep
) {
  (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
    (decSep = typeof decSep === "undefined" ? "." : decSep);
  thouSep = typeof thouSep === "undefined" ? "," : thouSep;
  const sign = number < 0 ? "-" : "";
  const i = String(
    parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
  );

  let j = 0;
  j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : "") +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
    (decPlaces
      ? decSep +
      Math.abs(number - i)
        .toFixed(decPlaces)
        .slice(2)
      : "")
  );
}