import Axios from '@/services/axios'
import { getAuthHeader } from "@/helpers"

export async function deleteServiceFeaturedMedia(payload) {
  const response = await Axios.delete(`events/service/media/featured`, { params: payload, headers: getAuthHeader() })
  return response.data
}
export async function uploadServiceFeaturedMedia(form) {
  const response = await Axios.put(`events/service/media/featured`, form, { headers: getAuthHeader() })
  return response.data
}