import Axios from '@/services/axios'
import { getAuthHeader, getMultiFormAuthHeader } from "@/helpers"
import { getUrlPrefix } from "@/services/api/utils"


export async function updateAccommodationRoom(payload) {
  const response = await Axios.put(`${getUrlPrefix()}/room`, payload, {
    headers: getMultiFormAuthHeader()
  })
  return response.data
}
export async function deleteAccommodationRoom(payload) {
  const response = await Axios.delete(`${getUrlPrefix()}/room`, { params: payload, headers: getAuthHeader() })
  return response.data
}
export async function deleteRoomFeatureMedia(payload) {
  const response = await Axios.delete(`${getUrlPrefix()}/room/media/featured`, { params: payload, headers: getAuthHeader() })
  return response.data
}
export async function uploadRoomFeaturedMedia(form) {
  const response = await Axios.put(`${getUrlPrefix()}/room/media/featured`, form, { headers: getAuthHeader() })
  return response.data
}

export async function checkoutRoom(payload) {
  const url = `${getUrlPrefix()}/checkout-room`
  const response = await Axios.post(url, payload, { headers: getAuthHeader() })
  return response.data
}
