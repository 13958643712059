import apiClient from '@/services/axios'
import { getAuthHeader } from "@/helpers"
/*
 * Users
 */
export function getUsers(query) {
  return apiClient.get('/users', {
    params: query,
    headers: getAuthHeader(),
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function getUser(id, query) {
  return apiClient.get('/user/' + id, {
    params: query,
    headers: getAuthHeader(),
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function getPendingUsers() {
  return apiClient.get('/pending-users', {
    headers: getAuthHeader(),
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function getRejectedUsers() {
  return apiClient.get('/rejected-users', {
    headers: getAuthHeader(),
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function dashboardInit() {
  return apiClient.get('/dashboardInit/', {
    headers: getAuthHeader(),
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function approveUser(id, data) {
  return apiClient.put('/account/approval/' + id, data, {headers: getAuthHeader(),})
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function activateUser(id) {
  return apiClient.put('/activate/' + id, {}, {headers: getAuthHeader(),})
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}
