import MainLayout from '@/layouts/Main'

export default {
  path: '/events',
  component: MainLayout,
  redirect: "/events/dashboard",
  meta: {
    authRequired: true,
    roles: ['events']
  },
  children: [
    {
      path: 'onboarding',
      meta: {
        title: 'Onboarding',
        authRequired: true,
        roles: ['events']
      },
      component: () => import('@/pages/new/onboarding'),
    },
    {
      path: 'dashboard',
      meta: {
        title: 'Dashboard',
        authRequired: true,
        roles: ['events']
      },
      component: () => import('@/pages/new/shared/dashboard'),
    },
    {
      path: 'business-information',
      meta: {
        title: 'Profile',
        authRequired: true,
        roles: ['events'],
        // permissions: ["can_update_business_information"]
      },
      component: () => import('@/pages/new/shared/settings/business-info'),
    },
    {
      path: 'business-media',
      meta: {
        title: 'Media',
        authRequired: true,
        roles: ['events']
      },
      component: () => import('@/pages/new/shared/settings/business-media'),
    },
    {
      path: 'bank',
      meta: {
        title: 'Bank Account Details',
        authRequired: true,
        roles: ['events'],
        // permissions: ["can_update_bank_information"]
      },
      component: () => import('@/pages/new/shared/settings/business-bank'),
    },
    {
      path: 'preview',
      meta: {
        title: 'Preview',
        authRequired: true,
        roles: ['events']
      },
      component: () => import('@/pages/new/shared/preview'),
    },
    {
      path: 'services',
      meta: {
        title: 'Services',
        authRequired: true,
        roles: ['events'],
        // permissions: ["can_add_service"]
      },
      props: () => ({
        entity_type: "events",
      }),
      component: () => import('@/pages/new/shared/services'),
    },
    {
      path: 'staff',
      meta: {
        title: 'Add Entity User',
        authRequired: true,
        roles: ['events'],
        permissions: ['can_add_staff']
      },
      component: () => import('@/pages/new/shared/staff'),
    },
    {
      path: 'transactions',
      meta: {
        title: 'Transactions',
        authRequired: true,
        roles: ['events'],
        permissions: ["can_view_transactions"]
      },
      component: () => import('@/pages/new/shared/transactions/listing'),
    },
    {
      path: 'transaction/:transaction_id',
      meta: {
        title: 'Transaction',
        authRequired: true,
        roles: ['events'],
        permissions: ["can_view_transactions"]

      },
      component: () => import('@/pages/new/shared/transactions/details'),
    },
    {
      path: 'receive-cash-transaction',
      meta: {
        title: 'Receive Cash Transaction',
        authRequired: true,
        roles: ['events']
      },
      component: () => import('@/pages/new/shared/transactions/receive-cash'),
    },
    {
      path: 'confirm-transaction',
      meta: {
        title: 'Confirm Transaction',
        authRequired: true,
        roles: ['events']
      },
      component: () => import('@/pages/new/shared/transactions/confirm'),
    },
    {
      path: 'reviews',
      meta: {
        title: 'Reviews',
        authRequired: true,
        roles: ['events'],
        permissions: ["can_view_reviews"]
      },
      component: () => import('@/pages/new/shared/reviews'),
    },
  ]
}
