import 'ant-design-vue/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles
import 'vue-loading-overlay/dist/vue-loading.css';

import Vue from 'vue'
import VuePageTitle from 'vue-page-title'
import NProgress from 'vue-nprogress'
import VueLayers from 'vuelayers'
import BootstrapVue from 'bootstrap-vue'
import { getUserAccountProfileModule } from './store/utils'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './localization'
import { get } from "lodash"
import './antd'
import * as VueGoogleMaps from 'vue2-google-maps'

import VueTruncate from 'vue-truncate-filter'
Vue.use(VueTruncate)

Vue.use(BootstrapVue)
Vue.use(VueLayers)

Vue.use(NProgress)
Vue.use(VuePageTitle, {
  prefix: 'Visit Ghana | ',
  router,
})

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
  },
})

Vue.config.productionTip = false
const nprogress = new NProgress({ parent: 'body' })


Vue.mixin({
  computed: {
    getUserAccountProfileModule() {
      return getUserAccountProfileModule()
    }
  },
  methods: {
    getValueFromSource(source, path, defaultValue = "") {
      return get(source, path, defaultValue);
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    formattedPrice(value) {
      try {
        const newValue = value
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return `₵ ${newValue}`;
      } catch (error) {
        return `₵ ${value}`;
      }
    },
  }
})

//Global Filters
Vue.filter("formatCurrency", (amount) => {
  const fraction = 2
  if (!amount) {
    return '¢' + 0.00
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: fraction,
    maximumFractionDigits: 2,
  });

  return '¢' + formatter.format(amount);
})

Vue.filter("formatMomentDate", (date) => {
  return date.format('MMMM Do, YYYY')
})
//Global Filters

new Vue({
  router,
  store,
  nprogress,
  i18n,
  render: h => h(App),
}).$mount('#app')
