import {
  publicGetEntities,
  publicGetEntity,
} from "@/services/api/public"

import {
  PUBLIC_QUERY_ENTITIES,
  PUBLIC_GET_ENTITY
} from "@/store/actions"

export const state = {
  loadingGet: [],
}

export const mutations = {
  SET_STATE(state, payload) {
    Object.assign(state, {
      ...payload,
    })
  },
};

export const actions = {

  async [PUBLIC_GET_ENTITY]({ commit }, query = {}) {
    try {
      commit('SET_STATE', { loadingGet: true })
      const response = await publicGetEntity(query);
      if (response && response.data) {
        return response.data
      }
    } finally {
      commit('SET_STATE', { loadingGet: false })
    }
  },

  async [PUBLIC_QUERY_ENTITIES]({ commit }, query) {
    try {
      commit('SET_STATE', { loadingGet: true })
      const response = await publicGetEntities(query);
      return response;
    } finally {
      commit('SET_STATE', { loadingGet: false })
    }
  },
}

export const getters = {
  tourSites: (state) => state.tour_sites,
  transactions: (state) => state.transactions ? state.transactions : [],

}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}