export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';
export const AUTH_CHECK_TOKEN = 'AUTH_CHECK_TOKEN';
export const AUTH_GET_ACCOUNT_AND_PROFILE = 'AUTH_GET_ACCOUNT_AND_PROFILE'
export const AUTH_CONFIRM_RESET_PASSWORD = 'AUTH_CONFIRM_RESET_PASSWORD'
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD'

export const MISC_GET_COUNTRIES = 'MISC_GET_COUNTRIES'
export const MISC_GET_GEO_DATA = 'MISC_GET_GEO_DATA'
export const MISC_GET_BANKS_BRANCHES = 'MISC_GET_BANKS_BRANCHES'
export const MISC_GET_BANKS = 'MISC_GET_BANKS'

// tour sight
export const ADD_TOUR_SIGHT_SERVICE = 'ADD_TOUR_SIGHT_SERVICE'
export const DELETE_TOUR_SIGHT_SERVICE = 'DELETE_TOUR_SIGHT_SERVICE'
export const GET_TOUR_SIGHT_MANAGER = 'GET_TOUR_SIGHT_MANAGER'
export const UPDATE_TOUR_SIGHT_INFORMATION = 'UPDATE_TOUR_SIGHT_INFORMATION'
export const UPDATE_TOUR_SIGHT_BANK_INFORMATION = 'UPDATE_TOUR_SIGHT_BANK_INFORMATION'
export const COMPLETE_TOUR_SIGHT_MANAGER_ONBOARDING = 'COMPLETE_TOUR_SIGHT_MANAGER_ONBOARDING'
export const ADD_ACCOMMODATIONS_SERVICE = 'ADD_ACCOMMODATIONS_SERVICE'
export const DELETE_ACCOMMODATIONS_SERVICE = 'DELETE_ACCOMMODATIONS_SERVICE'
export const GET_ACCOMMODATIONS_MANAGER = 'GET_ACCOMMODATIONS_MANAGER'
export const UPDATE_ACCOMMODATIONS_INFORMATION = 'UPDATE_ACCOMMODATIONS_INFORMATION'
export const UPDATE_ACCOMMODATIONS_BANK_INFORMATION = 'UPDATE_ACCOMMODATIONS_BANK_INFORMATION'
//account general
export const UPDATE_PROFILE = "UPDATE_ACCOUNT_INFORMATION"
export const GET_PROFILE = "GET_PROFILE"
export const COMPLETE_ONBOARDING = "COMPLETE_ACCOUNT_ONBOARDING"

export const UPDATE_PROFILE_SERVICE = "UPDATE_PROFILE_SERVICE"
export const DELETE_PROFILE_SERVICE = "DELETE_PROFILE_SERVICE"
export const UPDATE_PROFILE_BANK_INFORMATION = "UPDATE_PROFILE_BANK_INFORMATION"

export const DELETE_MEDIA = 'DELETE_MEDIA'
export const UPLOAD_SERVICE_FEATURED_MEDIA = 'UPLOAD_SERVICE_FEATURED_MEDIA'
export const DELETE_SERVICE_FEATURED_MEDIA = 'DELETE_SERVICE_FEATURED_MEDIA'
export const UPLOAD_MEDIA = 'UPLOAD_MEDIA'
export const DELETE_FEATURED_MEDIA = 'DELETE_FEATURED_MEDIA'
export const UPLOAD_FEATURED_MEDIA = 'UPLOAD_FEATURED_MEDIA'
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_BANKS = 'GET_BANKS'
export const GET_BANKS_BRANCHES = 'GET_BANKS_BRANCHES'
export const AUTH_REGISTER = 'AUTH_REGISTER'
export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT'
export const AUTH_REGISTER_ENTITY_USER = "AUTH_REGISTER_ENTITY_USER"

//ACCOMMODATIONS
export const UPDATE_ACCOMMODATION_ROOM = "UPDATE_ACCOMMODATION_ROOM"
export const DELETE_ACCOMMODATION_ROOM = "DELETE_ACCOMMODATION_ROOM"
export const UPLOAD_ROOM_FEATURED_MEDIA = "UPLOAD_ROOM_FEATURED_MEDIA"
export const DELETE_ROOM_FEATURED_MEDIA = "DELETE_ROOM_FEATURED_MEDIA"
export const UPDATE_TRANSPORT_SERVICES = "UPDATE_TRANSPORT_SERVICES"
export const DELETE_TRANSPORT_SERVICES = "DELETE_TRANSPORT_SERVICES"
export const CHECKOUT_ROOM = "CHECKOUT_ROOM"
export const RELEASE_CAR = "RELEASE_CAR"

//EVENTS
export const DELETE_EVENT_SERVICE_FEATURED_MEDIA = "DELETE_EVENT_SERVICE_FEATURED_MEDIA"
export const UPLOAD_EVENT_SERVICE_FEATURED_MEDIA = "UPLOAD_EVENT_SERVICE_FEATURED_MEDIA"


//
export const DELETE_FOOD_AND_BEVERAGES_SERVICE_FEATURED_MEDIA = 'DELETE_FOOD_AND_BEVERAGES_SERVICE_FEATURED_MEDIA'
export const UPLOAD_FOOD_AND_BEVERAGES_SERVICE_FEATURED_MEDIA = 'UPLOAD_FOOD_AND_BEVERAGES_SERVICE_FEATURED_MEDIA'

export const DELETE_TOURSIGHT_SERVICE_FEATURED_MEDIA = 'DELETE_TOURSIGHT_SERVICE_FEATURED_MEDIA'
export const UPLOAD_TOURSIGHT_SERVICE_FEATURED_MEDIA = 'UPLOAD_TOURSIGHT_SERVICE_FEATURED_MEDIA'
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS'
export const GET_TRANSACTION = 'GET_TRANSACTION'
export const CONFIRM_TRANSACTION = 'CONFIRM_TRANSACTION'
export const VERIFY_PAYSTACK_TRANSACTION = 'VERIFY_PAYSTACK_TRANSACTION'
export const GET_PAYMENT_SERVICE = 'GET_PAYMENT_SERVICE'

export const DELETE_GENERIC_MEDIA = "DELETE_GENERIC_MEDIA"
export const UPLOAD_GENERIC_MEDIA = "UPLOAD_GENERIC_MEDIA"


//tourist
export const COMPLETE_TOURIST_ONBOARDING = "COMPLETE_TOURIST_ONBOARDING"
export const QUERY_ENTITIES = "QUERY_ENTITIES"
export const GET_ENTITY = "GET_ENTITY"
export const GET_TOUR_SITES = "GET_TOUR_SITES"
export const ORDER_SERVICE = "ORDER_SERVICE"

export const CONFIRM_CASH_PAYMENT = "CONFIRM_CASH_PAYMENT"

export const PUBLIC_QUERY_ENTITIES = "PUBLIC_QUERY_ENTITIES"
export const PUBLIC_GET_ENTITY = "PUBLIC_GET_ENTITY"
