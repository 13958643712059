import Axios from '@/services/axios'
import { getAuthHeader } from "@/helpers"
import { getUrlPrefix } from "@/services/api/utils"


export async function deleteGenericMedia(payload) {
  const response = await Axios.delete(`misc/media`, { params: payload, headers: getAuthHeader() })
  return response.data
}

export async function uploadGenericMedia(form) {
  const response = await Axios.post(`misc/media`, form, {headers: {...getAuthHeader()} })
  return response.data
}

export async function deleteMedia({ userType, payload }) {
  const response = await Axios.delete(`${getUrlPrefix(userType)}/media`, { data: payload, headers: getAuthHeader() })
  return response.data
}

export async function uploadMedia({ userType, form }) {
  const response = await Axios.post(`${getUrlPrefix(userType)}/media`, form, {headers: {...getAuthHeader()} })
  return response.data
}

export async function uploadFeaturedMedia({ userType, form }) {
  const url = `${getUrlPrefix(userType)}/media/featured`
  const response = await Axios.put(url, form, { headers: {...getAuthHeader() } })
  return response.data
}
export async function uploadServiceFeaturedMedia( form ) {
  const url = `${getUrlPrefix()}/service/media/featured`
  const response = await Axios.put(url,form, { headers: {...getAuthHeader()}})
  return response.data
}

export async function deleteFeaturedMedia({ userType, payload }) {
  const url = `${getUrlPrefix(userType)}/media/featured`
  const response = await Axios.delete(url, { params: payload, headers: getAuthHeader() })
  return response.data
}
export async function deleteServiceFeaturedMedia(payload) {
  const url = `${getUrlPrefix()}/service/media/featured`
  const response = await Axios.delete(url, { params: payload, headers: getAuthHeader() })
  return response.data
}
