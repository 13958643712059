import { defaultUserMedia } from "@/store/defaultData"

export default {
  namespaced: true,
  state: {
    account: {
      media: defaultUserMedia,
    },
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    UPDATE_ACCOUNT(state, payload) {
      const update = { ...state.account, ...payload }
      Object.assign(state, {
        account: update
      })
    },
  },
  actions: {},
  getters: {
    services: (state) => state.account.services,
    tourOperatorAccount: (state) => state.account,
    account: (state) => state.account,
    transactions: (state) => state.account.transactions? state.account.transactions.filter(transaction=>transaction.status!=="deleted"):[],
    
    tourOperatorMedia: state => state.account && state.account.media ? state.account.media : defaultUserMedia,
    media: state => state.account && state.account.media ? state.account.media : defaultUserMedia,
    tourOperatorReviews: (state) => state.account.reviews,
    tourOperatorBankDetails: (state) => state.account.bank ? state.account.bank : { country_code: '', bank_id: '', account_number: '' },
  },
}
