import Axios from '@/services/axios'

import { getAuthHeader, getMultiFormAuthHeader } from "@/helpers"

export async function addOrUpdateService(payload) {
  return Axios.put('toursight/service', payload, {
    headers: getMultiFormAuthHeader()
  })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteService(payload) {
  return Axios.delete('toursight/service', {
    headers: getAuthHeader(),
    params: {
      ...payload,
    },
  })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function completeOnboarding(payload) {
  console.log('data out', payload)
  return Axios.post('toursight/complete-onboarding', payload, { headers: getAuthHeader() })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTourSightManager() {
  return Axios.get('toursight/', { headers: getAuthHeader() })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function saveInformation(payload) {
  const response = await Axios.put('toursight/information', payload, { headers: getAuthHeader() })
  return response.data
}

export async function deleteServiceFeaturedMedia(payload) {
  const response = await Axios.delete(`toursight/service/media/featured`, { params: payload, headers: getAuthHeader() })
  return response.data
}

export async function uploadServiceFeaturedMedia(form) {
  const response = await Axios.put(`toursight/service/media/featured`, form, { headers: getAuthHeader() })
  return response.data
}