
import PublicLayout from '@/layouts/Public'

import facilityPreviewRoutes from "./facility-preview-routes"
import facilityServiceRoutes from "./facility-services-routes"

export default {
  path: '/browse',
  component: PublicLayout,
  redirect: "/browse/gta-facilities",
  children: [
    {
      path: 'gta-facilities',
      meta: {
        title: 'Visit Ghana: Explore Now !!',
      },
      component: () => import("@/pages/new/public/facilities/listing"),
    },
    ...facilityPreviewRoutes,
    ...facilityServiceRoutes
  ],
}