var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"list-unstyled"},[_c('li',{class:_vm.$style.item},[_c('a',{class:_vm.$style.itemLink,attrs:{"href":"javascript: void(0);"}},[_c('div',{staticClass:"font-weight-bold text-danger",class:_vm.$style.itemMeta},[_vm._v(" 2:28 "),_c('br'),_vm._v(" PM ")]),_vm._m(0),_c('div',{class:_vm.$style.itemAction},[_c('span'),_c('span')])])]),_c('li',{class:_vm.$style.item},[_c('a',{class:_vm.$style.itemLink,attrs:{"href":"javascript: void(0);"}},[_c('div',{staticClass:"font-weight-bold text-blue",class:_vm.$style.itemMeta},[_vm._v(" 1:02 "),_c('br'),_vm._v(" AM ")]),_vm._m(1),_c('div',{class:_vm.$style.itemAction},[_c('span'),_c('span')])])]),_c('li',{class:_vm.$style.item},[_c('a',{class:_vm.$style.itemLink,attrs:{"href":"javascript: void(0);"}},[_c('div',{staticClass:"font-weight-bold",class:_vm.$style.itemMeta},[_vm._v(" 2:28 "),_c('br'),_vm._v(" PM ")]),_vm._m(2),_c('div',{class:_vm.$style.itemAction},[_c('span'),_c('span')])])]),_c('li',{class:_vm.$style.item},[_c('a',{class:_vm.$style.itemLink,attrs:{"href":"javascript: void(0);"}},[_c('div',{staticClass:"font-weight-bold",class:_vm.$style.itemMeta},[_vm._v(" 2:28 "),_c('br'),_vm._v(" PM ")]),_vm._m(3),_c('div',{class:_vm.$style.itemAction},[_c('span'),_c('span')])])]),_c('li',{class:_vm.$style.item},[_c('a',{class:_vm.$style.itemLink,attrs:{"href":"javascript: void(0);"}},[_c('div',{staticClass:"font-weight-bold",class:_vm.$style.itemMeta},[_vm._v(" 2:28 "),_c('br'),_vm._v(" PM ")]),_vm._m(4),_c('div',{class:_vm.$style.itemAction},[_c('span'),_c('span')])])]),_c('li',{class:_vm.$style.item},[_c('a',{class:_vm.$style.itemLink,attrs:{"href":"javascript: void(0);"}},[_c('div',{staticClass:"font-weight-bold",class:_vm.$style.itemMeta},[_vm._v(" 2:28 "),_c('br'),_vm._v(" PM ")]),_vm._m(5),_c('div',{class:_vm.$style.itemAction},[_c('span'),_c('span')])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-3"},[_c('div',[_vm._v("Payment Received")]),_c('div',{staticClass:"text-muted"},[_vm._v("Mary has approved your quote.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-3"},[_c('div',[_vm._v("Account Activated")]),_c('div',{staticClass:"text-muted"},[_vm._v("Mary has approved your quote.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-3"},[_c('div',[_vm._v("Payment Received")]),_c('div',{staticClass:"text-muted"},[_vm._v("Mary has approved your quote.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-3"},[_c('div',[_vm._v("Payment Received")]),_c('div',{staticClass:"text-muted"},[_vm._v("Mary has approved your quote.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-3"},[_c('div',[_vm._v("Payment Received")]),_c('div',{staticClass:"text-muted"},[_vm._v("Mary has approved your quote.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-3"},[_c('div',[_vm._v("Payment Received")]),_c('div',{staticClass:"text-muted"},[_vm._v("Mary has approved your quote.")])])
}]

export { render, staticRenderFns }