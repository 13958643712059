import {
  getTourSights,
  getEntities,
  getFeaturedEntities,
  getEntity,
  updateTourist,
  orderService,
  getAllTransactions,
  getTransaction,
  verifyPaystackTransaction,
  getPaymentService
} from "@/services/api/tourist"

import {
  GET_TOUR_SITES,
  QUERY_ENTITIES,
  GET_ENTITY,
  UPDATE_PROFILE,
  ORDER_SERVICE,
  GET_TRANSACTIONS,
  GET_TRANSACTION,
  VERIFY_PAYSTACK_TRANSACTION,
  GET_PAYMENT_SERVICE
} from "@/store/actions"

import {
  SET_CURRENT_USER
} from "@/store/mutations"

export const state = {
  loadingGet: false,
  tour_sites: [],
  hotel_and_accommodations: [],
  food_and_drinks: [],
  events: [],
  transports: [],
  tour_operators: [],
  transactions: []
}

export const mutations = {
  SET_STATE(state, payload) {
    Object.assign(state, {
      ...payload,
    })
  },
};

export const actions = {
  async [GET_TOUR_SITES]({ commit, state }, query = {}) {
    try {
      commit('SET_STATE', { loadingGet: true })
      const response = await getTourSights(query);
      if (response && response.data) {
        commit('SET_STATE', { tour_sites: response.data })
        return response.data
      }
    } finally {
      commit('SET_STATE', { loadingGet: false })
    }
  },

  async [ORDER_SERVICE]({ commit, state }, data) {
    try {
      commit('SET_STATE', { loadingGet: true })
      const response = await orderService(data);
      if (response && response.data) {
        return response.data
      }
    } finally {
      commit('SET_STATE', { loadingGet: false })
    }
  },

  async [GET_ENTITY]({ commit, state }, query = {}) {
    try {
      commit('SET_STATE', { loadingGet: true })
      const response = await getEntity(query);
      if (response && response.data) {
        return response.data
      }
    } finally {
      commit('SET_STATE', { loadingGet: false })
    }
  },

  async [QUERY_ENTITIES]({ commit }, query) {
    try {
      commit('SET_STATE', { loadingGet: true })
      const response = await getEntities(query);
      return response;
    } finally {
      commit('SET_STATE', { loadingGet: false })
    }
  },

  async [UPDATE_PROFILE]({ commit, state }, payload) {
    try {
      commit('SET_STATE', { loadingGet: true })
      const response = await updateTourist(payload);
      if (response && response.data) {
        commit(`auth/${SET_CURRENT_USER}`, response.data, { root: true })
        return response.data
      }
    } catch (e) {
      console.log(e)
      throw new Error(e)
    }
    finally {
      commit('SET_STATE', { loadingGet: false })
    }
  },

  async [GET_TRANSACTIONS]({ commit, state }, query) {
    try {
      commit('SET_STATE', { loadingGet: true })
      const response = await getAllTransactions(query);
      if (response && response.data) {
        // commit("SET_STATE", { transactions: response.data })
        return response
      }
    } catch (e) {
      console.log(e)
      throw new Error(e)
    }
    finally {
      commit('SET_STATE', { loadingGet: false })
    }
  },
  async [GET_TRANSACTION]({ commit, state }, id) {
    const response = await getTransaction(id)
    if (response && response.data) {
      return response.data
    }
    return response
  },

  async [VERIFY_PAYSTACK_TRANSACTION]({ _ }, payment_reference) {
    const response = await verifyPaystackTransaction(payment_reference)
    if (response && response.data) {
      return response.data
    }
    return response
  },

  async [GET_PAYMENT_SERVICE]({ _ }) {
    const response = await getPaymentService()
    if (response && response.data) {
      return response.data
    }
    return response
  },
}

export const getters = {
  tourSites: (state) => state.tour_sites,
  transactions: (state) => state.transactions ? state.transactions : [],

}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}