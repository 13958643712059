import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth"
import settings from "./modules/settings"
import misc from './modules/misc'

// import user from './user'
import media from './media'
import toursight from './modules/toursight'
import admin from './modules/admin'

import profile from "./modules/profile"
import touroperator from "./modules/touroperator"
import nourishment from "./modules/food_and_drinks"
import events from "./modules/events"
import accommodations from "./modules/accommodations"
import transport from "./modules/transport"
import tourist from "./modules/tourist"
import publicStore from "./modules/public"


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    settings,
    // user,
    toursight,
    media,
    misc,
    admin,
    profile,
    touroperator,
    nourishment,
    events,
    accommodations,
    transport,
    tourist,
    public: publicStore
  }
})
