import {
  ADD_TOUR_SIGHT_SERVICE,
  DELETE_TOUR_SIGHT_SERVICE,
  GET_TOUR_SIGHT_MANAGER,
  UPDATE_TOUR_SIGHT_INFORMATION,
  COMPLETE_TOUR_SIGHT_MANAGER_ONBOARDING,
  UPDATE_TOUR_SIGHT_BANK_INFORMATION,
  DELETE_TOURSIGHT_SERVICE_FEATURED_MEDIA,
  UPLOAD_TOURSIGHT_SERVICE_FEATURED_MEDIA
} from '@/store/actions'
import {
  addOrUpdateService,
  deleteService,
  getTourSightManager,
  saveInformation,
  completeOnboarding,
  deleteServiceFeaturedMedia,
  uploadServiceFeaturedMedia
} from '@/services/api/toursight'

import { defaultUserMedia } from "@/store/defaultData"


export default {
  namespaced: true,
  state: {
    account: {
      media: defaultUserMedia,
    },
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    UPDATE_ACCOUNT(state, payload) {
      const update = { ...state.account, ...payload }
      Object.assign(state, {
        account: update
      })
    },
  },
  actions: {
    async [ADD_TOUR_SIGHT_SERVICE]({ commit, state }, payload) {
      try {
        const response = await addOrUpdateService(payload)
        if (response && response.data) {
          commit('SET_STATE', { account: { ...state.account, ...response.data } })
          return response.data.services
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    async [UPDATE_TOUR_SIGHT_INFORMATION]({ commit, state }, payload) {
      const response = await saveInformation(payload)
      if (response && response.data) {
        commit('SET_STATE', { account: { ...state.account, ...response.data } })
        return response.data
      }
    },
    async [DELETE_TOUR_SIGHT_SERVICE]({ commit, state }, id) {
      try {
        const response = await deleteService({ service_id: id })
        if (response && response.data) {
          commit('SET_STATE', { account: { ...state.account, ...response.data } })
          return response.data.services
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    async [GET_TOUR_SIGHT_MANAGER]({ commit, state }) {
      try {
        const response = await getTourSightManager()
        if (response && response.data) {
          commit('SET_STATE', { account: { ...state.account, ...response.data.toursight } })
          return response.data
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    async [COMPLETE_TOUR_SIGHT_MANAGER_ONBOARDING]({ commit, state }, payload) {
      try {
        const response = await completeOnboarding(payload)
        if (response && response.data) {
          commit('SET_STATE', { account: { ...state.account, ...response.data } })
          commit('CHANGE_SETTING', { setting: 'showSideBar', value: true }, { root: true })
          return response.data
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    async [DELETE_TOURSIGHT_SERVICE_FEATURED_MEDIA]({ commit }, { service_id }) {
      try {
        const response = await deleteServiceFeaturedMedia({ service_id })
        if (response && response.data) {
          commit(`UPDATE_ACCOUNT`, response.data)
          return response.data.services
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    async [UPLOAD_TOURSIGHT_SERVICE_FEATURED_MEDIA]({ commit }, { form }) {
      try {
        const response = await uploadServiceFeaturedMedia(form)
        if (response && response.data) {
          commit(`UPDATE_ACCOUNT`, response.data)
          return response.data.services
        }
      } catch (e) {
        throw new Error(e)
      }
    }
  },
  getters: {
    services: (state) => state.account.services,
    tourSightAccount: (state) => state.account,
    account: (state) => state.account,
    transactions: (state) => state.account.transactions ? state.account.transactions.filter(transaction => transaction.status !== "deleted") : [],

    tourSightMedia: state => state.account && state.account.media ? state.account.media : defaultUserMedia,
    media: state => state.account && state.account.media ? state.account.media : defaultUserMedia,
    tourSightReviews: (state) => state.account.reviews,
    tourSightBankDetails: (state) => state.account.bank ? state.account.bank : { country_code: '', bank_id: '', account_number: '' },
  },
}
