
const MENU_TITLES = {
  dashboard: 'Dashboard',
  updateBusinessData: 'Update Business Information',
  updateMedia: 'Upload Your Business Media',
  updateServices: 'Manage Your Services',
  updateRooms: 'Manage Rooms',
  reviews: 'Reviews',
  bank: 'Bank Accounts',
  staff: 'My Staff',
  confirmTransactions: 'Confirm Transaction',
  receiveCashTransaction: 'Receive Cash Transaction',
  transactions: 'My Transactions',
  preview: 'Preview'
}

export default {
  admin: [
    {
      title: 'DASHBOARD',
      key: 'dashboards',
      icon: 'fe fe-home',
      url: '/admin/dashboard',
    },
    {
      title: 'USERS',
      key: 'users',
      icon: 'fe fe-users',
      url: '/admin/users',
    },
    {
      title: 'ACTIVE USERS',
      key: 'activeUsers',
      icon: 'fe fe-user-check',
      url: '/admin/active/users',
    },
    {
      title: 'PENDING USERS',
      key: 'pendingUsers',
      icon: 'fe fe-user-minus',
      url: '/admin/pending/users',
    },
    {
      title: 'REJECTED USERS',
      key: 'rejectedUsers',
      icon: 'fe fe-user-x',
      url: '/admin/rejected/users',
    },
  ],
  tourist: [
    {
      title: 'TRANSACTIONS',
      key: 'transactions',
      icon: 'fe fe-file-plus',
      url: '/tourist/transactions',
    },
    {
      title: "MENU",
      key: "main-menu",
      category: "none"
    },
    {
      title: 'All',
      key: 'all-facilities',
      icon: 'lnr lnr-apartment',
      url: '/tourist/gta-facilities/all',
    },
    {
      title: 'Accommodations',
      key: 'hotels',
      icon: 'lnr lnr-apartment',
      url: '/tourist/gta-facilities/hotel-and-accommodations',
    },
    {
      title: 'Sites & Attractions',
      key: 'sites_and_attractions',
      icon: 'fe fe-globe',
      url: '/tourist/gta-facilities/tour-sites',
    },
    {
      title: 'Food & Beverages',
      key: 'food',
      icon: 'fa fa-glass',
      url: '/tourist/gta-facilities/food-and-beverages',
    },
    {
      title: 'Travel Trade',
      key: 'travel_and_tour',
      icon: 'fe fe-map',
      url: '/tourist/gta-facilities/tour-operators',
    },
    {
      title: 'Transport Rentals',
      key: 'transports_and_rentals',
      icon: 'fe fe-truck',
      url: '/tourist/gta-facilities/transports',
    },
    {
      title: 'Events',
      key: 'events',
      icon: 'fe fe-clipboard',
      url: '/tourist/gta-facilities/events',
    }
  ],
  hotel_and_accommodations: [
    {
      title: MENU_TITLES.dashboard,
      key: 'dashboards',
      icon: 'fe fe-home',
      url: '/accommodations/dashboard',
    },
    {
      title: MENU_TITLES.updateBusinessData,
      key: 'business-information',
      icon: 'fe fe-user',
      url: '/accommodations/business-information',
      // permissions: ["can_update_business_information"]
    },
    {
      title: MENU_TITLES.updateMedia,
      key: 'business-media',
      icon: 'fe fe-image',
      url: '/accommodations/business-media',
    },
    {
      title: MENU_TITLES.bank,
      key: 'bank',
      icon: 'fe fe-dollar-sign',
      url: '/accommodations/bank',
      // permissions: ["can_update_bank_information"]
    },
    {
      title: MENU_TITLES.preview,
      key: 'preview',
      icon: 'fe fe-eye',
      url: '/accommodations/preview',
    },
    {
      title: MENU_TITLES.updateServices,
      key: 'services',
      icon: 'fe fe-truck',
      url: '/accommodations/services',
      // permissions: ["can_add_service"]
    },
    {
      title: MENU_TITLES.updateRooms,
      key: 'room',
      icon: 'fe fe-home',
      url: '/accommodations/rooms',
      // permissions: ["can_add_service"]
    },
    {
      title: MENU_TITLES.staff,
      key: 'manage_staff',
      icon: 'fe fe-users',
      url: '/accommodations/staff',
      permissions: ["can_add_staff"]
    },
    {
      title: MENU_TITLES.transactions,
      key: 'transactions',
      icon: 'fe fe-file-plus',
      url: '/accommodations/transactions',
      permissions: ["can_view_transactions"]
    },
    {
      title: MENU_TITLES.receiveCashTransaction,
      key: 'receive-cash-transaction',
      icon: 'fe fe-file-text',
      url: '/accommodations/receive-cash-transaction',
    },
    {
      title: MENU_TITLES.confirmTransactions,
      key: 'confirm-transaction',
      icon: 'fe fe-file-text',
      url: '/accommodations/confirm-transaction',
    },
    {
      title: MENU_TITLES.reviews,
      key: 'reviews',
      icon: 'fe fe-star',
      url: '/accommodations/reviews',
      permissions: ["can_view_reviews"]
    }
  ],
  tour_sight: [
    {
      title: MENU_TITLES.dashboard,
      key: 'dashboards',
      icon: 'fe fe-home',
      url: '/toursight/dashboard',
    },
    {
      title: MENU_TITLES.updateBusinessData,
      key: 'business-information',
      icon: 'fe fe-user',
      url: '/toursight/business-information',
      // permissions: ["can_update_business_information"]
    },
    {
      title: MENU_TITLES.updateMedia,
      key: 'business-media',
      icon: 'fe fe-image',
      url: '/toursight/business-media',
    },
    {
      title: MENU_TITLES.bank,
      key: 'bank',
      icon: 'fe fe-dollar-sign',
      url: '/toursight/bank',
      // permissions: ["can_update_bank_information"]
    },
    {
      title: MENU_TITLES.preview,
      key: 'preview',
      icon: 'fe fe-eye',
      url: '/toursight/preview',
    },
    {
      title: MENU_TITLES.updateServices,
      key: 'services',
      icon: 'fe fe-truck',
      url: '/toursight/services',
      // permissions: ["can_add_service"]
    },
    {
      title: MENU_TITLES.staff,
      key: 'entity_users',
      icon: 'fe fe-users',
      url: '/toursight/staff',
      permissions: ["can_add_staff"]
    },
    {
      title: MENU_TITLES.transactions,
      key: 'transactions',
      icon: 'fe fe-file-plus',
      url: '/toursight/transactions',
      permissions: ["can_view_transactions"]
    },
    {
      title: MENU_TITLES.receiveCashTransaction,
      key: 'receive-cash-transaction',
      icon: 'fe fe-file-text',
      url: '/toursight/receive-cash-transaction',
    },
    {
      title: MENU_TITLES.confirmTransactions,
      key: 'confirm-transaction',
      icon: 'fe fe-file-text',
      url: '/toursight/confirm-transaction',
    },
    {
      title: MENU_TITLES.reviews,
      key: 'reviews',
      icon: 'fe fe-star',
      url: '/toursight/reviews',
      permissions: ["can_view_reviews"]
    },
  ],
  food_and_drinks: [
    {
      title: MENU_TITLES.dashboard,
      key: 'dashboards',
      icon: 'fe fe-home',
      url: '/nourishment/dashboard',
    },
    {
      title: MENU_TITLES.updateBusinessData,
      key: 'profile',
      icon: 'fe fe-user',
      url: '/nourishment/business-info',
      // permissions: ["can_update_business_information"]
    },
    {
      title: MENU_TITLES.updateMedia,
      key: 'media',
      icon: 'fe fe-image',
      url: '/nourishment/business-media',
    },
    {
      title: MENU_TITLES.bank,
      key: 'bank',
      icon: 'fe fe-dollar-sign',
      url: '/nourishment/bank',
      // permissions: ["can_update_bank_information"]
    },
    {
      title: MENU_TITLES.preview,
      key: 'preview',
      icon: 'fe fe-eye',
      url: '/nourishment/preview',
    },
    {
      title: MENU_TITLES.updateServices,
      key: 'services',
      icon: 'fe fe-truck',
      url: '/nourishment/services',
      // permissions: ["can_add_service"]
    },
    {
      title: MENU_TITLES.staff,
      key: 'staff',
      icon: 'fe fe-users',
      url: '/nourishment/staff',
      permissions: ["can_add_staff"]
    },
    {
      title: MENU_TITLES.transactions,
      key: 'transactions',
      icon: 'fe fe-file-plus',
      url: '/nourishment/transactions',
      permissions: ["can_view_transactions"]
    },
    {
      title: MENU_TITLES.receiveCashTransaction,
      key: 'receive-cash-transaction',
      icon: 'fe fe-file-text',
      url: '/nourishment/receive-cash-transaction',
    },
    {
      title: MENU_TITLES.confirmTransactions,
      key: 'confirm-transaction',
      icon: 'fe fe-file-text',
      url: '/nourishment/confirm-transaction',
    },
    {
      title: MENU_TITLES.reviews,
      key: 'reviews',
      icon: 'fe fe-star',
      url: '/nourishment/reviews',
      permissions: ["can_view_reviews"]
    }
  ],
  tour_operator: [
    {
      title: MENU_TITLES.dashboard,
      key: 'dashboards',
      icon: 'fe fe-home',
      url: '/touroperator/dashboard',
    },
    {
      title: MENU_TITLES.updateBusinessData,
      key: 'business-information',
      icon: 'fe fe-user',
      url: '/touroperator/business-information',
      // permissions: ["can_update_business_information"]
    },
    {
      title: MENU_TITLES.updateMedia,
      key: 'business-media',
      icon: 'fe fe-image',
      url: '/touroperator/business-media',
    },
    {
      title: MENU_TITLES.bank,
      key: 'bank',
      icon: 'fe fe-dollar-sign',
      url: '/touroperator/bank',
      // permissions: ["can_update_bank_information"]
    },
    {
      title: MENU_TITLES.preview,
      key: 'preview',
      icon: 'fe fe-eye',
      url: '/touroperator/preview',
    },
    {
      title: MENU_TITLES.updateServices,
      key: 'services',
      icon: 'fe fe-truck',
      url: '/touroperator/services',
      // permissions: ["can_add_service"]
    },
    {
      title: MENU_TITLES.staff,
      key: 'entity_users',
      icon: 'fe fe-users',
      url: '/touroperator/staff',
      permissions: ["can_add_staff"]
    },
    {
      title: MENU_TITLES.transactions,
      key: 'transactions',
      icon: 'fe fe-file-plus',
      url: '/touroperator/transactions',
      permissions: ["can_view_transactions"]
    },
    {
      title: MENU_TITLES.receiveCashTransaction,
      key: 'receive-cash-transaction',
      icon: 'fe fe-file-text',
      url: '/touroperator/receive-cash-transaction',
    },
    {
      title: MENU_TITLES.confirmTransactions,
      key: 'confirm-transaction',
      icon: 'fe fe-file-text',
      url: '/touroperator/confirm-transaction',
    },
    {
      title: MENU_TITLES.reviews,
      key: 'reviews',
      icon: 'fe fe-star',
      url: '/touroperator/reviews',
      permissions: ["can_view_reviews"]
    },
  ],
  transport: [
    {
      title: MENU_TITLES.dashboard,
      key: 'dashboards',
      icon: 'fe fe-home',
      url: '/transport/dashboard',
    },
    {
      title: MENU_TITLES.updateBusinessData,
      key: 'business-information',
      icon: 'fe fe-user',
      url: '/transport/business-information',
      // permissions: ["can_update_business_information"]
    },
    {
      title: MENU_TITLES.updateMedia,
      key: 'business-media',
      icon: 'fe fe-image',
      url: '/transport/business-media',
    },
    {
      title: MENU_TITLES.bank,
      key: 'bank',
      icon: 'fe fe-dollar-sign',
      url: '/transport/bank',
      // permissions: ["can_update_bank_information"]
    },
    {
      title: MENU_TITLES.preview,
      key: 'preview',
      icon: 'fe fe-eye',
      url: '/transport/preview',
    },
    {
      title: MENU_TITLES.updateServices,
      key: 'services',
      icon: 'fe fe-truck',
      url: '/transport/services',
      // permissions: ["can_add_service"]
    },
    {
      title: MENU_TITLES.staff,
      key: 'entity_users',
      icon: 'fe fe-users',
      url: '/transport/staff',
      permissions: ["can_add_staff"]
    },
    {
      title: MENU_TITLES.transactions,
      key: 'transactions',
      icon: 'fe fe-file-plus',
      url: '/transport/transactions',
      permissions: ["can_view_transactions"]
    },
    {
      title: MENU_TITLES.receiveCashTransaction,
      key: 'receive-cash-transaction',
      icon: 'fe fe-file-text',
      url: '/transport/receive-cash-transaction',
    },
    {
      title: MENU_TITLES.confirmTransactions,
      key: 'confirm-transaction',
      icon: 'fe fe-file-text',
      url: '/transport/confirm-transaction',
    },
    {
      title: MENU_TITLES.reviews,
      key: 'reviews',
      icon: 'fe fe-star',
      url: '/transport/reviews',
      permissions: ["can_view_reviews"]
    },
  ],
  events: [
    {
      title: MENU_TITLES.dashboard,
      key: 'dashboards',
      icon: 'fe fe-home',
      url: '/events/dashboard',
    },
    {
      title: MENU_TITLES.updateBusinessData,
      key: 'business-information',
      icon: 'fe fe-user',
      url: '/events/business-information',
      // permissions: ["can_update_business_information"]
    },
    {
      title: MENU_TITLES.updateMedia,
      key: 'business-media',
      icon: 'fe fe-image',
      url: '/events/business-media',
    },
    {
      title: MENU_TITLES.bank,
      key: 'bank',
      icon: 'fe fe-dollar-sign',
      url: '/events/bank',
      // permissions: ["can_update_bank_information"]
    },
    {
      title: MENU_TITLES.preview,
      key: 'preview',
      icon: 'fe fe-eye',
      url: '/events/preview',
    },
    {
      title: MENU_TITLES.updateServices,
      key: 'services',
      icon: 'fe fe-truck',
      url: '/events/services',
      // permissions: ["can_add_service"]
    },
    {
      title: MENU_TITLES.staff,
      key: 'entity_users',
      icon: 'fe fe-users',
      url: '/events/staff',
      permissions: ["can_add_staff"]
    },
    {
      title: MENU_TITLES.transactions,
      key: 'transactions',
      icon: 'fe fe-file-plus',
      url: '/events/transactions',
      permissions: ["can_view_transactions"]
    },
    {
      title: MENU_TITLES.receiveCashTransaction,
      key: 'receive-cash-transaction',
      icon: 'fe fe-file-text',
      url: '/events/receive-cash-transaction',
    },
    {
      title: MENU_TITLES.confirmTransactions,
      key: 'confirm-transaction',
      icon: 'fe fe-file-text',
      url: '/events/confirm-transaction',
    },
    {
      title: MENU_TITLES.reviews,
      key: 'reviews',
      icon: 'fe fe-star',
      url: '/events/reviews',
      permissions: ["can_view_reviews"]
    },
  ],

}
