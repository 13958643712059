import { defaultUserMedia } from "@/store/defaultData"
import {
  UPDATE_TRANSPORT_SERVICES,
  DELETE_TRANSPORT_SERVICES,
  UPLOAD_SERVICE_FEATURED_MEDIA,
  DELETE_SERVICE_FEATURED_MEDIA,
  RELEASE_CAR
} from '@/store/actions'
import {
  deleteServicesFeatureMedia,
  uploadServicesFeaturedMedia,
  deleteTransportServices,
  updateTransportServices,
  releaseCar
} from '@/services/api/transport'
export default {
  namespaced: true,
  state: {
    account: {
      media: defaultUserMedia,
    },
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    UPDATE_ACCOUNT(state, payload) {
      const update = { ...state.account, ...payload }
      Object.assign(state, {
        account: update
      })
    }

  },
  actions: {
    async [UPDATE_TRANSPORT_SERVICES]({ commit }, payload) {
      try {
        const response = await updateTransportServices(payload)
        if (response && response.data) {
          commit(`UPDATE_ACCOUNT`, response.data)
          return response.data.services
        }
      } catch (e) {
        console.log(e)
        throw new Error(e)
      }
    },
    async [DELETE_TRANSPORT_SERVICES]({ commit }, id) {
      try {
        const response = await deleteTransportServices({ service_id: id })
        if (response && response.data) {
          commit(`UPDATE_ACCOUNT`, response.data)
          return response.data.services
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    async [RELEASE_CAR]({ commit }, { rental_id }) {
      try {
        const response = await releaseCar({ rental_id })
        if (response && response.data) {
          return response.data
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    async [DELETE_SERVICE_FEATURED_MEDIA]({ commit }, { service_id, featured_id }) {
      try {
        const response = await deleteServicesFeatureMedia({ service_id, featured_id })
        if (response && response.data) {
          commit(`UPDATE_ACCOUNT`, response.data)
          return response.data.services
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    async [UPLOAD_SERVICE_FEATURED_MEDIA]({ commit }, { service_id, files }) {
      try {
        const form = new FormData();
        files.forEach(file => {
          form.append('featured', file);
        })


        form.append('service_id', service_id)
        const response = await uploadServicesFeaturedMedia(form)
        if (response && response.data) {
          commit(`UPDATE_ACCOUNT`, response.data)
          return response.data.services
        }
      } catch (e) {
        throw new Error(e)
      }
    }
  },
  getters: {
    services: (state) => state.account.services ? state.account.services.filter(service => service.status !== "deleted") : [],
    account: (state) => state.account,
    transactions: (state) => state.account.transactions ? state.account.transactions.filter(transaction => transaction.status !== "deleted") : [],

    media: state => state.account && state.account.media ? state.account.media : defaultUserMedia,
    transportReviews: (state) => state.account.reviews,
    transportBankDetails: (state) => state.account.bank ? state.account.bank : { country_code: '', bank_id: '', account_number: '' },
  },
}
