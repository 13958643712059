import { defaultUserMedia } from "@/store/defaultData"
import {
  DELETE_EVENT_SERVICE_FEATURED_MEDIA,
  UPLOAD_EVENT_SERVICE_FEATURED_MEDIA
} from "@/store/actions"

import {
  uploadServiceFeaturedMedia,
  deleteServiceFeaturedMedia
} from "@/services/api/events"

export default {
  namespaced: true,
  state: {
    account: {
      media: defaultUserMedia,
    },
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    UPDATE_ACCOUNT(state, payload) {
      const update = { ...state.account, ...payload }
      Object.assign(state, {
        account: update
      })
    },
  },
  actions: {
    async [DELETE_EVENT_SERVICE_FEATURED_MEDIA]({ commit }, { service_id, featured_id }) {
      try {
        const response = await deleteServiceFeaturedMedia({ service_id, featured_id })
        if (response && response.data) {
          commit(`UPDATE_ACCOUNT`, response.data)
          return response.data.services
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    async [UPLOAD_EVENT_SERVICE_FEATURED_MEDIA]({ commit }, { form }) {
      try {
        const response = await uploadServiceFeaturedMedia(form)
        if (response && response.data) {
          commit(`UPDATE_ACCOUNT`, response.data)
          return response.data.services
        }
      } catch (e) {
        throw new Error(e)
      }
    }
  },
  getters: {
    services: (state) => state.account.services,
    transactions: (state) => state.account.transactions ? state.account.transactions.filter(transaction => transaction.status !== "deleted") : [],
    eventsAccount: (state) => state.account,
    eventsMedia: state => state.account && state.account.media ? state.account.media : defaultUserMedia,
    eventsReviews: (state) => state.account.reviews,
    account: (state) => state.account,
    media: state => state.account && state.account.media ? state.account.media : defaultUserMedia,
    eventsBankDetails: (state) => state.account.bank ? state.account.bank : { country_code: '', bank_id: '', account_number: '' },
  },
}
