
import {
  getGeoData,
  getCountries,
  getBanks,
  getBanksBranches,
  confirmTransaction,
  confirmCashPayment,
  getTransaction
} from "@/services/api/misc"
import {
  MISC_GET_COUNTRIES,
  MISC_GET_GEO_DATA,
  MISC_GET_BANKS,
  MISC_GET_BANKS_BRANCHES,
  CONFIRM_TRANSACTION,
  GET_TRANSACTION,
  CONFIRM_CASH_PAYMENT
} from "../actions"

import {merge} from "lodash"
export const state = {
  countries: [],
  geoData: {
    states: [],
    cities: [],
  },
  archivedGeoData:{
    states: [],
    cities: [],
  },
  banksForCountry: [],
  banksBranches: [],
  loadingGeoData: false,
  loadingBankData: false
}

export const mutations = {
  SET_STATE(state, payload) {
    Object.assign(state, {
      ...payload,
    })
  },
};

export const actions = {
  async [MISC_GET_COUNTRIES]({ commit, state }) {
    try {
      commit('SET_STATE', { loadingGeoData: true })
      const countriesReq = await getCountries();
      if (countriesReq && countriesReq.data) {
        commit('SET_STATE', { countries: [...countriesReq.data] })
        return countriesReq.data
      }
    } finally {
      commit('SET_STATE', { loadingGeoData: false })
    }
  },
  async [MISC_GET_GEO_DATA]({ commit, state }, { geoId,type }) {
    try {
      commit('SET_STATE', { loadingGeoData: true })
      const geoDataReq = await getGeoData(geoId);
      if (geoDataReq && geoDataReq.data) {
        const geoData = state.geoData
        geoData[type] = geoDataReq.data.geonames
        if(type === 'states'){
          geoData['cities']  = []
        }
        const archivedGeoData = state.archivedGeoData
        archivedGeoData[type] = merge(geoDataReq.data.geonames,archivedGeoData[type])
        commit('SET_STATE', { geoData,archivedGeoData })
        return geoDataReq.data
      }
    } finally {
      commit('SET_STATE', { loadingGeoData: false })
    }
  },
  async [MISC_GET_BANKS]({ commit }, countryIsoCode) {
    try {
      commit('SET_STATE', { loadingBankData: true })
      const banksReq = await getBanks(countryIsoCode);
      if (banksReq && banksReq.data) {
        commit('SET_STATE', { banksForCountry: banksReq.data })
        return banksReq.data
      }
    } finally {
      commit('SET_STATE', { loadingBankData: false })
    }
  },
  async [MISC_GET_BANKS_BRANCHES]({ commit }, bankId) {
    const bankBranchesReq = await getBanksBranches(bankId);
    if (bankBranchesReq && bankBranchesReq.data) {
      commit('SET_STATE', { banksBranches: bankBranchesReq.data })
      return bankBranches.data
    }
  },
  async [CONFIRM_TRANSACTION]({ commit }, payload) {
    console.log(payload)
    const transaction = await confirmTransaction(payload);

    return transaction
  },
  async [CONFIRM_CASH_PAYMENT]({ commit }, payload) {
    const response = await confirmCashPayment(payload);
    return response.data
  },
  async [GET_TRANSACTION]({ commit, state }, id) {
    const response = await getTransaction(id)
    if (response && response.data) {
      return response.data
    }
    return response
  },
}

export const getters = {
  getCountriesList: (state) => state.countries.filter(country => country.geo_name_id),
  // below dependent on countries
  getGeoDataStates: (state) => {
    return state.geoData.states ? state.geoData.states.filter(states => states.geonameId) : []
  },
  getGeoDataCities: (state) => {
    return state.geoData.cities ? state.geoData.cities.filter(cities => cities.geonameId) : []
  },
  archivedGeoData:(state)=>state.archivedGeoData,
  getBanksForCountry: (state) => state.banksForCountry,
  getBankBranches: (state) => state.banksBranches,
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}