import MainLayout from '@/layouts/Main'

export default {
  path: '/admin',
  component: MainLayout,
  redirect: "/admin/dashboard",
  meta: {
    authRequired: true,
    roles: ['admin']
  },
  children: [
    {
      path: '/admin/dashboard',
      meta: {
        title: 'Dashboard',
        authRequired: true,
        roles: ['admin']
      },
      component: () => import('@/pages/admin/adminDashboard'),

    },
    {
      path: '/admin/users',
      meta: {
        title: 'Users',
        authRequired: true,
        roles: ['admin']
      },
      component: () => import('@/pages/admin/users'),
    },
    {
      path: '/admin/active/users',
      meta: {
        title: 'Active Users',
        authRequired: true,
        roles: ['admin']
      },
      component: () => import('@/pages/admin/activeUsers'),
    },
    {
      path: '/admin/pending/users',
      meta: {
        title: 'Pending Users',
        authRequired: true,
        roles: ['admin']
      },
      component: () => import('@/pages/admin/pendingUsers'),
    },
    {
      path: '/admin/rejected/users',
      meta: {
        title: 'Rejected Users',
        authRequired: true,
        roles: ['admin']
      },
      component: () => import('@/pages/admin/rejectedUsers'),
    },
    {
      path: '/admin/user/:user_type/:user_id',
      name: 'user',
      meta: {
        title: 'User',
        authRequired: true,
        roles: ['admin']
      },
      component: () => import('@/pages/admin/singleUser'),
    },
  ]
}
