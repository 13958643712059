<template>
  <a-layout>
    <a-layout-content>
      <cui-sidebar />
      <div
        :class="{
          [$style.container]: true,
          cui__layout__squaredBorders: settings.isSquaredBorders,
          cui__layout__cardsShadow: settings.isCardShadow,
          cui__layout__borderless: settings.isBorderless,
          [$style.white]: settings.authPagesColor === 'white',
          [$style.gray]: settings.authPagesColor === 'gray',
        }"
        :style="{
          backgroundImage:
            settings.authPagesColor === 'image'
              ? `url(resources/images/content/photos/7.jpg)`
              : 'none',
        }"
      >
        <div
          :class="{
            [$style.topbar]: true,
            [$style.topbarGray]: settings.isGrayTopbar,
          }"
        >
          <router-link to="/">
            <div :class="$style.logoContainer">
              <div :class="$style.logo">
                <img
                  src="@/assets/images/logo.png"
                  class="mr-2"
                  alt="Visit Ghana"
                  style="width: 150px"
                />
                <div :class="$style.descr">{{ settings.version }}</div>
              </div>
            </div>
          </router-link>

          <div class="d-none d-sm-block">
            <span class="mr-2">Don't have an account?</span>
            <router-link
              to="/auth/register"
              class="font-size-16 kit__utils__link"
              >Sign up</router-link
            >
            |

            <router-link to="/browse" class="font-size-16 kit__utils__link"
              >Browse Facilities</router-link
            >
          </div>
        </div>
        <div :class="$style.containerInner">
          <transition :name="settings.routerAnimation" mode="out-in">
            <router-view />
          </transition>
        </div>
        <div class="mt-auto pb-5 pt-5">
          <div class="text-center">
            Copyright © 2017-{{ new Date().getFullYear() }} Ghana Tourism
            Authority |
            <a href="/privacy" target="_blank" rel="noopener noreferrer"
              >Privacy Policy</a
            >
          </div>
        </div>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { mapState } from "vuex";
import CuiSidebar from "@/components/cleanui/layout/Sidebar";

export default {
  name: "AuthLayout",
  components: { CuiSidebar },
  computed: mapState(["settings"]),
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
