
import AuthLayout from '@/layouts/Auth'
export default {
  path: '/auth',
  component: AuthLayout,
  redirect: 'auth/login',
  children: [
    {
      path: '/auth/login',
      meta: {
        title: 'Sign In',
      },
      component: () => import('@/pages/new/auth/login'),
    },
    {
      path: '/auth/register',
      meta: {
        title: 'Sign Up',
      },
      component: () => import('@/pages/new/auth/register/register.vue'),
    },
    {
      path: '/auth/register-entity',
      meta: {
        title: 'Sign Up',
      },
      component: () => import('@/pages/new/auth/register/register-entity.vue'),
    },
    {
      path: '/auth/forgot-password',
      meta: {
        title: 'Forgot Password',
      },
      component: () => import('@/pages/new/auth/reset-password'),
    }
  ],
}