import Axios from '@/services/axios'
import { getAuthHeader } from "@/helpers"

export async function getTourSights(query) {
  const response = await Axios.get('tourist/tour-sites', { headers: getAuthHeader(), params: query })
  return response.data
}

export async function getFeaturedEntities(query) {
  const response = await Axios.get('tourist/featured-entities', { headers: getAuthHeader(), params: query })
  return response.data
}

export async function getEntity(query) {
  const response = await Axios.get('tourist/entity', { headers: getAuthHeader(), params: query })
  return response.data
}

export async function getEntities(query) {
  const response = await Axios.get('tourist/entities-search', { headers: getAuthHeader(), params: query })
  return response.data
}

export async function getTransactions(query) {
  const response = await Axios.get('tourist/entity', { headers: getAuthHeader(), params: query })
  return response.data
}
export async function getAllTransactions(query) {
  const response = await Axios.get('tourist/transactions', { headers: getAuthHeader(), params: query })
  return response.data
}

export async function updateTourist(payload) {
  const response = await Axios.put('tourist/account', payload, { headers: getAuthHeader() })
  return response.data
}

export async function orderService(payload) {
  const response = await Axios.post('tourist/request-order', payload, { headers: getAuthHeader() })
  return response.data
}

export async function getTransaction(id) {
  return Axios.get(`tourist/transactions/${id}`, { headers: getAuthHeader() })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)))
}

export async function verifyPaystackTransaction(payment_reference) {
  return Axios.get(`tourist/payments/verify/${payment_reference}`, {
    headers: getAuthHeader()
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)))
}

export async function getPaymentService() {
  return Axios.get(`tourist/payments/gateway`, {
    headers: getAuthHeader()
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)))
}