import MainLayout from '@/layouts/Main'

export default {
  path: '/tourist',
  redirect: "/tourist/gta-facilities",
  component: MainLayout,
  meta: {
    authRequired: true,
    roles: ['tourist']
  },
  children: [
    {
      path: 'onboarding',
      meta: {
        title: 'Onboarding',
        authRequired: true,
        roles: ['tourist']
      },
      component: () => import('@/pages/new/tourist/onboarding'),
    },
    {
      path: 'gta-facilities',
      redirect: "/tourist/gta-facilities/all",
      meta: {
        title: 'Dashboard',
        authRequired: true,
        roles: ['tourist']
      },
      component: () => import('@/pages/new/tourist/home'),
      children: [
        {
          path: "all",
          component: () => import("@/pages/new/tourist/facilities/listing/all"),
          meta: {
            title: 'All Facilities',
            authRequired: true,
            roles: ['tourist']
          }
        },
        {
          path: "hotel-and-accommodations",
          component: () => import("@/pages/new/tourist/facilities/listing"),
          meta: {
            title: 'Accommodation',
            authRequired: true,
            roles: ['tourist']
          },
          props: () => ({
            entity_type: "hotel_and_accommodations",
            base_url: "gta-facilities/hotel-and-accommodations",
            data_key: "hotel_and_accommodations",
            entity_name: "Accommodation"
          })
        },
        {
          path: "tour-sites",
          component: () => import("@/pages/new/tourist/facilities/listing"),
          meta: {
            title: 'Site & Attractions',
            authRequired: true,
            roles: ['tourist']
          },
          props: () => ({
            entity_type: "tour_sight",
            base_url: "gta-facilities/tour-sites",
            data_key: "tour_sites",
            entity_name: "Site & Attractions"
          })
        },
        {
          path: "food-and-beverages",
          component: () => import("@/pages/new/tourist/facilities/listing"),
          meta: {
            title: 'Food & Beverages',
            authRequired: true,
            roles: ['tourist']
          },
          props: () => ({
            entity_type: "food_and_drinks",
            base_url: "gta-facilities/food-and-beverages",
            data_key: "food_and_drinks",
            entity_name: "Food & Beverages"
          })
        },
        {
          path: "transports",
          component: () => import("@/pages/new/tourist/facilities/listing"),
          meta: {
            title: 'Transport & Rentals',
            authRequired: true,
            roles: ['tourist']
          },
          props: () => ({
            entity_type: "transport",
            base_url: "gta-facilities/transports",
            data_key: "transports",
            entity_name: "Transport & Rentals"
          })
        },
        {
          path: "tour-operators",
          component: () => import("@/pages/new/tourist/facilities/listing"),
          meta: {
            title: 'Travel Trade',
            authRequired: true,
            roles: ['tourist']
          },
          props: () => ({
            entity_type: "tour_operator",
            base_url: "gta-facilities/tour-operators",
            data_key: "tour_operators",
            entity_name: "Travel Trade"
          })
        },
        {
          path: "events",
          component: () => import("@/pages/new/tourist/facilities/listing"),
          meta: {
            title: 'Events',
            authRequired: true,
            roles: ['tourist']
          },
          props: () => ({
            entity_type: "events",
            base_url: "gta-facilities/events",
            data_key: "events",
            entity_name: "Events"
          })
        }
      ]
    },
    {
      path: 'gta-facilities/tour-sites/:id',
      meta: {
        title: 'Attraction Site',
        authRequired: true,
        roles: ['tourist']
      },
      props: () => ({
        entity_type: "tour_sight",
        base_url: "tour-sites",
        title: 'Attraction Site',
      }),
      component: () => import('@/pages/new/tourist/facilities/preview'),
    },
    {
      path: 'gta-facilities/transports/:id',
      meta: {
        title: 'Transports',
        authRequired: true,
        roles: ['tourist']
      },
      props: () => ({
        entity_type: "transport",
        base_url: "transports",
        title: 'Transports',
      }),
      component: () => import('@/pages/new/tourist/facilities/preview'),
    },
    {
      path: 'gta-facilities/food-and-beverages/:id',
      meta: {
        title: 'Food & Beverages',
        authRequired: true,
        roles: ['tourist']
      },
      props: () => ({
        entity_type: "food_and_drinks",
        base_url: "food-and-beverages",
        title: 'Food & Beverages',
      }),
      component: () => import('@/pages/new/tourist/facilities/preview'),
    },
    {
      path: 'gta-facilities/events/:id',
      meta: {
        title: 'Events',
        authRequired: true,
        roles: ['tourist']
      },
      props: () => ({
        entity_type: "events",
        base_url: "events",
        title: 'Events',
      }),
      component: () => import('@/pages/new/tourist/facilities/preview'),
    },
    {
      path: 'gta-facilities/tour-operators/:id',
      props: () => ({
        entity_type: "tour_operator",
        base_url: "tour-operators",
        title: 'Tours & Travel',
      }),
      meta: {
        title: 'Tour Operator',
        authRequired: true,
        roles: ['tourist']
      },
      component: () => import('@/pages/new/tourist/facilities/preview'),
    },
    {
      path: 'gta-facilities/hotel-and-accommodations/:id',
      props: () => ({
        entity_type: "hotel_and_accommodations",
        base_url: "hotel-and-accommodations",
      }),
      meta: {
        title: 'Hotels and Accommodations',
        authRequired: true,
        roles: ['tourist']
      },
      component: () => import('@/pages/new/tourist/facilities/preview'),
    },
    //Services
    {
      path: 'gta-facilities/events/:id/services',
      meta: {
        title: 'Events',
        authRequired: true,
        roles: ['tourist']
      },
      props: () => ({
        entity_type: "events",
        base_url: "events",
      }),
      component: () => import('@/pages/new/tourist/facilities/services'),
    },
    {
      path: 'gta-facilities/tour-sites/:id/services',
      props: () => ({
        entity_type: "tour_sight",
        base_url: "tour-sites",
      }),
      meta: {
        title: 'Sites and Attractions',
        authRequired: true,
        roles: ['tourist']
      },
      component: () => import('@/pages/new/tourist/facilities/services'),
    },
    {
      path: 'gta-facilities/food-and-beverages/:id/services',
      props: () => ({
        entity_type: "food_and_drinks",
        base_url: "food-and-beverages"
      }),
      meta: {
        title: 'Sites and Attractions',
        authRequired: true,
        roles: ['tourist']
      },
      component: () => import('@/pages/new/tourist/facilities/services'),
    },
    {
      path: 'gta-facilities/transports/:id/services',
      props: () => ({
        entity_type: "transport",
        base_url: "transports",
      }),
      meta: {
        title: 'Transports',
        authRequired: true,
        roles: ['tourist']
      },
      component: () => import('@/pages/new/tourist/facilities/services'),
    },
    {
      path: 'gta-facilities/tour-operators/:id/services',
      props: () => ({
        entity_type: "tour_operator",
        base_url: "tour-operators",
        data_key: "tour_operators",
        entity_name: "Travel and Tour"
      }),
      meta: {
        title: 'Tour Operator Services',
        authRequired: true,
        roles: ['tourist']
      },
      component: () => import('@/pages/new/tourist/facilities/services'),
    },
    {
      path: 'gta-facilities/hotel-and-accommodations/:id/services',
      meta: {
        title: 'Hotels and Accommodations',
        authRequired: true,
        roles: ['tourist']
      },
      props: () => ({
        entity_type: "hotel_and_accommodations",
        base_url: "hotel-and-accommodations",
      }),
      component: () => import('@/pages/new/tourist/facilities/services/hotels'),
    },
    //End of Generic Services

    //Reviews
    {
      path: 'gta-facilities/events/:id/reviews',
      meta: {
        title: 'Event',
        authRequired: true,
        roles: ['tourist']
      },
      props: () => ({
        entity_type: "events",
        base_url: "events",
      }),
      component: () => import('@/pages/new/tourist/facilities/reviews'),
    },
    {
      path: 'gta-facilities/tour-sites/:id/reviews',
      props: () => ({
        entity_type: "tour_sight",
        base_url: "tour-sites",
      }),
      meta: {
        title: 'Sites and Attraction Reviews',
        authRequired: true,
        roles: ['tourist']
      },
      component: () => import('@/pages/new/tourist/facilities/reviews')
    },
    {
      path: 'gta-facilities/food-and-beverages/:id/reviews',
      props: () => ({
        entity_type: "food_and_drinks",
        base_url: "food-and-beverages"
      }),
      meta: {
        title: 'Food and Beverage Reviews',
        authRequired: true,
        roles: ['tourist']
      },
      component: () => import('@/pages/new/tourist/facilities/reviews')
    },
    {
      path: 'gta-facilities/transports/:id/reviews',
      props: () => ({
        entity_type: "transport",
        base_url: "transports",
      }),
      meta: {
        title: 'Transport Reviews',
        authRequired: true,
        roles: ['tourist']
      },
      component: () => import('@/pages/new/tourist/facilities/reviews')
    },
    {
      path: 'gta-facilities/tour-operators/:id/reviews',
      props: () => ({
        entity_type: "tour_operator",
        base_url: "tour-operators",
        data_key: "tour_operators",
        entity_name: "Travel and Tour"
      }),
      meta: {
        title: 'Tour Operator Reviews',
        authRequired: true,
        roles: ['tourist']
      },
      component: () => import('@/pages/new/tourist/facilities/reviews')
    },
    {
      path: 'gta-facilities/hotel-and-accommodations/:id/reviews',
      meta: {
        title: 'Hotels and Accommodation Reviews',
        authRequired: true,
        roles: ['tourist']
      },
      props: () => ({
        entity_type: "hotel_and_accommodations",
        base_url: "hotel-and-accommodations",
      }),
      component: () => import('@/pages/new/tourist/facilities/reviews')
    },
    //End of Generic Reviews
    {
      path: 'transactions',
      meta: {
        title: 'Transactions',
        authRequired: true,
        roles: ['tourist']
      },
      component: () => import('@/pages/new/tourist/transactions/listing'),
    },
    {
      path: 'transaction/:transaction_id',
      meta: {
        title: 'Transaction',
        authRequired: true,
        roles: ['tourist']
      },
      component: () => import('@/pages/new/tourist/transactions/details'),
    },
    {
      path: 'profile',
      meta: {
        title: 'Profile',
        authRequired: true,
        roles: ['tourist']
      },
      component: () => import('@/pages/new/tourist/profile'),
    }
  ]
}
