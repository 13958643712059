import {
  DELETE_MEDIA,
  UPLOAD_MEDIA,
  DELETE_FEATURED_MEDIA,
  UPLOAD_FEATURED_MEDIA,
  UPLOAD_SERVICE_FEATURED_MEDIA,
  DELETE_SERVICE_FEATURED_MEDIA,
  DELETE_GENERIC_MEDIA,
  UPLOAD_GENERIC_MEDIA
} from '@/store/actions'
import config from "../../config"


import {
  deleteMedia,
  uploadMedia,
  deleteFeaturedMedia,
  uploadFeaturedMedia,
  uploadServiceFeaturedMedia,
  deleteServiceFeaturedMedia,
  deleteGenericMedia,
  uploadGenericMedia
} from '@/services/api/media'

import { getUserAccountProfileModule } from "@/store/utils"
import store from "@/store"

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async [DELETE_GENERIC_MEDIA]({ commit }, payload) {
      const response = await deleteGenericMedia(payload)
      if (response && response.data) {
        const storeModule = getUserAccountProfileModule()
        commit(`${storeModule}/UPDATE_ACCOUNT`, response.data, { root: true })
        return response.data
      }
    },
    async [UPLOAD_GENERIC_MEDIA]({ commit }, payload) {
      const response = await uploadGenericMedia(payload)
      if (response && response.data) {
        const storeModule = getUserAccountProfileModule()
        commit(`${storeModule}/UPDATE_ACCOUNT`, response.data, { root: true })
        return response.data
      }
    },
    async [DELETE_MEDIA]({ commit }, payload) {
      const response = await deleteMedia(payload)
      if (response && response.data) {
        const storeModule = getUserAccountProfileModule()
        commit(`${storeModule}/UPDATE_ACCOUNT`, response.data, { root: true })
        return response.data
      }
    },
    async [UPLOAD_MEDIA]({ commit }, payload) {
      const response = await uploadMedia(payload)
      if (response && response.data) {
        const storeModule = getUserAccountProfileModule()
        commit(`${storeModule}/UPDATE_ACCOUNT`, response.data, { root: true })
        return response.data
      }
    },
    async [DELETE_FEATURED_MEDIA]({ commit }, payload) {
      const response = await deleteFeaturedMedia(payload)
      if (response && response.data) {
        const storeModule = getUserAccountProfileModule()
        commit(`${storeModule}/UPDATE_ACCOUNT`, response.data, { root: true })
        return response.data
      }
    },
    async [DELETE_SERVICE_FEATURED_MEDIA]({ commit }, payload) {
      const response = await deleteServiceFeaturedMedia(payload)
      if (response && response.data) {
        const storeModule = getUserAccountProfileModule()
        commit(`${storeModule}/UPDATE_ACCOUNT`, response.data, { root: true })
        return response.data
      }
    },
    async [UPLOAD_FEATURED_MEDIA]({ commit }, payload) {
      const response = await uploadFeaturedMedia(payload)
      if (response && response.data) {
        const storeModule = getUserAccountProfileModule()
        commit(`${storeModule}/UPDATE_ACCOUNT`, response.data, { root: true })
        return response.data
      }
    },
    async [UPLOAD_SERVICE_FEATURED_MEDIA]({ commit }, { service_id, file }) {
      const form = new FormData()
      console.log('upload featured', { service_id, file });

      form.append('featured', file);
      form.append('service_id', service_id)
      const response = await uploadServiceFeaturedMedia(form)
      if (response && response.data) {
        const storeModule = getUserAccountProfileModule()
        commit(`${storeModule}/UPDATE_ACCOUNT`, response.data, { root: true })
        return response.data
      }
    },
  },
  getters: {
    getMediaUrl: () => `${config.apiEndpoint}/toursight/media`,
  },
}