import Axios from '@/services/axios'
import { getAuthHeader, getMultiFormAuthHeader } from "@/helpers"
import { getUrlPrefix } from "@/services/api/utils"


export async function addOrUpdateService(payload) {
  return Axios.put(`${getUrlPrefix()}/service`, payload, {
    headers: getMultiFormAuthHeader()
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)))
}

export async function deleteService(payload) {
  return Axios.delete(`${getUrlPrefix()}/service`, {
    headers: getAuthHeader(),
    params: {
      ...payload,
    },
  })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)))
}

export async function completeOnboarding(payload) {
  return Axios.post(`account/complete-onboarding`, payload, { headers: getAuthHeader() })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)))
}

export async function completeTouristOnboarding(payload) {
  const response = await Axios.post(`tourist/complete-onboarding`, payload, { headers: getAuthHeader() })
  return response.data
}


export async function getProfile() {
  return Axios.get(getUrlPrefix(), { headers: getAuthHeader() })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)))
}

export async function updateProfile(payload) {
  return Axios.put(`${getUrlPrefix()}/information`, payload, { headers: getAuthHeader() })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)))
}

export async function saveBankInformation(payload) {
  return Axios.put(`account/bank`, payload, { headers: getAuthHeader() })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(new Error(error.response.statusText)))
}

