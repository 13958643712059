import { defaultUserMedia } from "@/store/defaultData"
import {
  UPDATE_ACCOMMODATION_ROOM, DELETE_ACCOMMODATION_ROOM,
  UPLOAD_ROOM_FEATURED_MEDIA, DELETE_ROOM_FEATURED_MEDIA,
  CHECKOUT_ROOM
} from '@/store/actions'
import {
  deleteRoomFeatureMedia,
  uploadRoomFeaturedMedia,
  deleteAccommodationRoom,
  updateAccommodationRoom,
  checkoutRoom
} from '@/services/api/accommodations'
export default {
  namespaced: true,
  state: {
    account: {
      media: defaultUserMedia,
    },
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    UPDATE_ACCOUNT(state, payload) {
      const update = { ...state.account, ...payload }
      Object.assign(state, {
        account: update
      })
    }

  },
  actions: {
    async [UPDATE_ACCOMMODATION_ROOM]({ commit }, payload) {
      try {
        const response = await updateAccommodationRoom(payload)
        if (response && response.data) {
          commit(`UPDATE_ACCOUNT`, response.data)
          return response.data.rooms
        }
      } catch (e) {
        console.log(e)
        throw new Error(e)
      }
    },
    async [DELETE_ACCOMMODATION_ROOM]({ commit }, id) {
      try {
        const response = await deleteAccommodationRoom({ room_id: id })
        if (response && response.data) {
          commit(`UPDATE_ACCOUNT`, response.data)
          return response.data.rooms
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    async [DELETE_ROOM_FEATURED_MEDIA]({ commit }, { room_id, featured_id }) {
      try {
        const response = await deleteRoomFeatureMedia({ room_id, featured_id })
        if (response && response.data) {
          commit(`UPDATE_ACCOUNT`, response.data)
          return response.data.rooms
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    async [CHECKOUT_ROOM]({ commit }, { booking_id }) {
      const response = await checkoutRoom({ booking_id })
      if (response && response.data) {
        return response.data
      }
    },
    async [UPLOAD_ROOM_FEATURED_MEDIA]({ commit }, { room_id, files }) {
      try {
        const form = new FormData()

        files.forEach(file => {
          form.append('featured', file);
        })


        form.append('room_id', room_id)
        const response = await uploadRoomFeaturedMedia(form)
        if (response && response.data) {
          commit(`UPDATE_ACCOUNT`, response.data)
          return response.data.rooms
        }
      } catch (e) {
        throw new Error(e)
      }
    }
  },
  getters: {
    services: (state) => state.account.services ? state.account.services.filter(service => service.status !== "deleted") : [],
    transactions: (state) => state.account.transactions ? state.account.transactions.filter(transaction => transaction.status !== "deleted") : [],
    rooms: (state) => state.account.rooms ? state.account.rooms.filter(room => room.status !== "deleted") : [],
    account: (state) => state.account,
    media: state => state.account && state.account.media ? state.account.media : defaultUserMedia,
    accommodationsReviews: (state) => state.account.reviews,
    accommodationsBankDetails: (state) => state.account.bank ? state.account.bank : { country_code: '', bank_id: '', account_number: '' },
  },
}
